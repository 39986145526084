.c-chat-observations {
    min-height: 600px;
    padding: 25px 0 0 0;
    overflow-y: auto;
    @include scrollbar(transparent, $color-info, 12px);
}

.c-chat-observations__header {
    @include flex-center-x;
    margin: 0 0 10px 0;
}