html body {
    .ct-grid {
        stroke: #979797;
        stroke-width: 2px;
        stroke-dasharray: 0;
        opacity: 0.1;
    }

    .ct-label.ct-vertical {
        color: rgba(#7D7D7D, 0.3);
        font-size: 13px;
        letter-spacing: 0;
        line-height: 1.5em;
        font-weight: $font-weight-semibold;
        min-width: 100px;

        &.ct-end {
            justify-content: flex-end;
            text-align: right;
        }
    }

    .ct-label.ct-horizontal {
        color: rgba(#7D7D7D, 0.3);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.5em;
        text-align: center;
        font-weight: $font-weight-semibold;
    }
}