.o-input {
    height: 38px;
    width: 100%;
    padding: 0 18px;
    font-weight: $font-weight-semibold;
    font-family: $font-family-primary;
    appearance: none;
    font-size: 12px;
    color: #7D7D7D;
    outline: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: rgba(#4a4a4a, 0.2);
    }

    &input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    &[disabled] {
        cursor: not-allowed;
        -webkit-text-fill-color: #7D7D7D;
        color: #7D7D7D;
        opacity: 1;
    }
}

// --- VARIANTS ---

// --- PRIMARY VARIANT ---
.o-input--primary {
    border-radius: 25px;
    background: #fff;
    border: 1px solid #D3D6DB;

    &:focus {
        box-shadow: 0 0 0 0.2em $color-info;
        border-color: $color-info;
    }

    &[disabled] {
        background-color: #F8F8F8;
    }
}

// --- SECONDARY VARIANT ---
.o-input--secondary {
    background: #F8F8F8;
    border: 0;
    border-radius: 25px;
}

// --- SIZES ---
.o-input--size-md {
    height: 35px;
}

.o-input--size-sm {
    height: 32px;
}

.o-input--size-lg {
    height: 49px;
    font-size: 15px;
}

// --- FAKE INPUT ---
.o-input--fake {
    line-height: 36px;

    .o-input--size-md {
        line-height: 33px;
    }
    
    .o-input--size-sm {
        line-height: 30px;
    }
    
    .o-input--size-lg {
        line-height: 47px;
    }

    &.o-input--secondary {
        line-height: 38px;

        .o-input--size-md {
            line-height: 35px;
        }
        
        .o-input--size-sm {
            line-height: 32px;
        }
        
        .o-input--size-lg {
            line-height: 49px;
        }
    }
}