.c-patient-calendar-event-group {
    @include flex-center-y;
    width: 100%;
}
.c-patient-calendar-event-group--hospital-calendar {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    align-items: flex-start;
    width: auto;
}

.c-patient-calendar-event-group--year-view {
    cursor: default;
}


.c-patient-calendar-event-group__badge,
.c-patient-calendar-event-group__content {
    transition: all 200ms ease-in-out;
}

.c-patient-calendar-event-group__badge {
    @include flex-center;
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    flex-grow: 0;
    background: $color-event-past;
    border-radius: 50%;
    font-size: 12px;
    font-weight: $font-weight-medium;
    letter-spacing: -0.2px;
    color: #fff;
    margin-right: -18px;
    position: relative;
    z-index: 2;
    box-shadow: -3px 5px 17px -3px rgba(0, 0, 0, 0);

    .event-type-icon {
        max-height: 16px;
        max-width: 16px;
        height: auto;
        width: auto;
        filter: invert(1) sepia(1) brightness(1.4);
    }

    .event-icon-past {
        margin: 0 -2px;
    }
}

.c-patient-calendar-event-group__content {
    flex-shrink: 1;
    flex-grow: 1;
    background: #F1F1F1;
    border-radius: 12.5px;
    font-size: 10px;
    font-weight: $font-weight-medium;
    line-height: 16px;
    padding: 5px 3px 5px 23px;
    color: #727272;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-shadow: 3px 5px 17px -3px rgba(0, 0, 0, 0);
}

.c-patient-calendar-event-group.is-selected {
    .c-patient-calendar-event-group__content {
        box-shadow: 3px 5px 17px -3px rgba(0, 0, 0, 0.35);
        padding: 5px 3px 5px 25px;
    }
    .c-patient-calendar-event-group__badge {
        transform: scale(1.15);
        box-shadow: -3px 5px 17px -3px rgba(0, 0, 0, 0.35);
    }
}


.c-patient-calendar-event-group.is-incoming {
    .c-patient-calendar-event-group__badge {
        background: $color-event-incoming;
    }
}

.c-patient-calendar-event-group.is-closest {
    .c-patient-calendar-event-group__badge {
        background: $color-event-closest;
    }
}

.c-patient-calendar-event-group.is-rejected {
    .c-patient-calendar-event-group__badge {
        background: $color-event-rejected;
    }
}

.c-patient-calendar-event-group--hospital-calendar {
    .c-patient-calendar-event-group__content {
        height: 100%;
    }

    .c-patient-event-presence {
        width: 17px;
        height: 17px;
        right: -2px;
        bottom: -2px;
    }
}