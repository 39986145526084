.c-patient-recommendation-add {
    padding: 0 0 12px;
}

.c-patient-recommendation-add__form {
    margin: 0 0 8px 0;

    &:last-child {
        margin: 0;
    }
}

.c-patient-recommendation-add__add-btn {
    position: relative;
    padding: 0 0 0 16px;
    font-size: 13px;
    line-height: 1em;
    color: #b1b1b1;
    font-weight: $font-weight-semibold;
    margin: 0 0 0 62px;
    
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 2px;
        background: #b1b1b1;
        height: 12px;
        top: 0;
        left: 5px;
    }

    &::after {
        transform: rotate(90deg);
    }
}

.c-patient-recommendation-add__popover-trigger {
    letter-spacing: 1px;
    padding: 0 10px 0 5px;
}

.c-patient-recommendation-add--template {
    .c-patient-recommendation-add__add-btn {
        padding: 0 0 20px 30px;
    }
}