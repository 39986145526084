.o-table-rows-brackets {
    position: absolute;
    left: -15px;
    top: 0;
    background: $color-info;
    height: 250px;
    width: 1px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        height: 1px;
        width: 10px;
        background: $color-info;
        z-index: 1;
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }
}

.o-table-rows-brackets__text {
    color: $color-info;
    font-size: 8px;
    background: #fff;
    font-weight: $font-weight-medium;
    letter-spacing: 0;
    line-height: 8px;
    position: absolute;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    padding: 1px;
    z-index: 2;
}