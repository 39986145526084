.c-education-subchapter-card-lessons {
    padding: 5px 0 10px 25px;
}

.c-education-subchapter-card-lessons__add-btn {
    position: relative;
    padding: 0 0 0 25px;
    font-size: 13px;
    line-height: 1em;
    color: #b1b1b1;
    font-weight: $font-weight-semibold;
    margin: 10px 0 0 0;
    
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 2px;
        background: #b1b1b1;
        height: 12px;
        top: 0;
        left: 5px;
    }

    &::after {
        transform: rotate(90deg);
    }
}
