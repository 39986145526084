.o-nav {
    display: flex;
}

.o-nav-item {
    @include flex-center();
    height: 55px;
    border: 1px solid #F1F1F1;
    border-top: 0;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    position: relative;

    &:not(:last-child) {
        border-right: 0;
    }
}

.o-nav-item__link {
    @include flex-center();
    height: 54px;
    width: 100%;
    padding: 0 20px;
    color: #CECECE;
    background: #fff;
    transition: all 200ms ease-in-out;

    &:hover {
        background: rgba(96,209,254,0.05);
        color: #CECECE;
        text-decoration: none;
    }
}

.o-nav-item__icon + .o-nav-item__title {
    margin: 0 0 0 10px;
}

.o-nav-item__icon--active {
    display: none;
}

.o-nav-item__badge {
    position: absolute;
    right: 4.5px;
    bottom: 3px;
}

.o-nav-item__dropdown-arrow {
    margin: 0 0 0 28px;
}

.o-nav-item__link--active {
    &, &:hover {
        color: $color-info;
        background: rgba(96,209,254,0.05);
    }

    .o-nav-item__icon {
        display: none;
    }

    .o-nav-item__icon--active {
        display: inline;
    }
}