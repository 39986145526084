
.o-checkbox-list {
    margin: 0 0 -13px 0;
}

.o-checkbox-list__group {
    padding-left: 27px;
}

.o-checkbox-list__item {
    margin: 0 0 13px 0;
    display: flex;
}

.o-checkbox-list__item-label {
    padding: 0 0 0 13px;
}