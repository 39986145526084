.c-report-legend {
    position: relative;
    padding: 14px 0 14px 24px;
    line-height: 16px;
    color: #727272;
    font-size: 12px;
    font-weight: 500;

    &::before {
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: 14px;
        left: 0;
        background-color: $color-success;
    }
}
.c-report-legend--info {
    &::before {
        background-color: $color-info;
    }
}