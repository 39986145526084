.o-datatable {
    font-family: $font-family-secondary;
    font-size: 13px;
    line-height: 1.25em;
    color: #727272;
}

.o-datatable__table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.o-datatable__row--body:nth-child(odd) {
    background: #f7fdff;
}

.o-datatable__cell {
    text-align: left;
    padding: 12px 8px;

    &:first-child {
        padding: 12px 8px 12px 16px;
    }
    &:last-child {
        padding: 12px 16px 12px 8px;
    }
}

.o-datatable__branch {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;

    &::after,
    &::before {
        content: '';
        position: absolute;
    }

    &::before {
        border-left: 1px dashed rgba(#4a4a4a, 0.5);
        left: 0;
        top: 2px;
        bottom: 0;
    }

    &::after {
        width: calc(100% - 15px);
        border-bottom: 1px dashed rgba(#4a4a4a, 0.5);
        left: 3px;
        top: 25px;
    }
}

.o-datatable__branch--last-on-level {
    &::before {
        height: 24px;
        bottom: auto;
    }
}

.o-datatable__title-branch {
    position: absolute;
    border-left: 1px dashed rgba(#4a4a4a, 0.5);
    left: 20px;
    bottom: 0;
}

.for-checking-height {
    min-height: 20px;
}

.o-datatable__cell--header {
    font-weight: $font-weight-bold;
    vertical-align: bottom;
}

.o-datatable__sort-icon {
    vertical-align: middle;
}

.o-datatable__cell--sortable {
    cursor: pointer;

    &:hover {
        background: #eee;
    }
}

.o-datatable__body {
    @include scrollbar(transparent, $color-info, 12px);
}

.o-datatable--scrollable {
    .o-datatable__body {
        overflow: auto;
    }
}
