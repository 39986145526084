.c-patient-activity-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c-patient-activity-card__description {
    color: #7D7D7D;
    font-size: 12px;
    font-weight: $font-weight-medium;
    letter-spacing: -0.1px;
    line-height: 11px;
    text-align: center;
    margin: 19px 0 0 0;
}

.c-patient-activity-card__icon {
    margin: 3px 0 0 0;
}