.o-hamburger {
    width: 35px;
    height: 35px;
    position: relative;
    border-radius: 50%;
    border: 1px solid $color-info;
    cursor: pointer;
}

.o-hamburger__bars {
    width: 20px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.o-hamburger__bar {
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    background: $color-info;
}
.o-hamburger__bar--first {
    top: 0;
}
.o-hamburger__bar--second {
    top: 6px;
}
.o-hamburger__bar--third {
    top: 12px;
}