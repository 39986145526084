.o-dropdown-menu {
    background: #fff;
    padding: 7px 0;
    bottom: auto;
    right: auto;
    z-index: map-get($z-index, dropdown);
    box-shadow: 0 11px 30px -10px rgba(0,0,0,0.21);
    border: 1px solid #F8F8F8;
    border-radius: 4px;
    display: none;
    // TODO
    margin: 0 !important;

    &.show {
        display: block;
    }
}