.o-dropdown-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: #fff;
    border: 1px solid #F1F1F1;	
    border-radius: 13px;
    color: #727272;
    font-family: Muli, sans-serif;
    font-size: 13px;	
    line-height: 1.2em;
    padding: 5px 10px 5px 11px;

    &::after {
        content: '';
        position: relative;
        margin: 0 0 0 19px;
        width: 8px;
        height: 4px;
        background: url('../../images/arrow_down.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}