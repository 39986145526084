.c-education-compendium-table-name {
    display: flex;
    align-items: flex-start;
}

.c-education-compendium-table-name__name {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.c-education-compendium-table-name__actions {
    flex-shrink: 0;
    margin: -2px 0 0 14px;


    .c-education-compendium-table-name__actions-menu {
        min-width: 200px;
    }
}

