.c-report-graph {
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.1);
    margin: 11px 0;
}

.c-report-graph__header {
    padding: 0 25px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 48px;
}