.o-wrapper {
    padding: 0 40px 20px;
    max-width: 1920px;
    margin: 0 auto;
}

.o-wrapper--full-height-table {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 55px);
}