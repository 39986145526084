.c-patient-card-header {
    display: flex;
    padding: 27px 25px 15px 0;
    position: relative;
}

.c-patient-card-header__avatar {
    width: 83px;
    height: 83px;
    background: $color-info;
    border-radius: 50%;
    margin: 0 20px 0 18px;
    flex-shrink: 0;
    @include flex-center();
}

.c-patient-card-header__avatar-img {
    width: 31px;
    height: auto;
}

.c-patient-card-header__name {
    color: #727272;
    font-size: 17px;
    line-height: 1.5em;
    font-weight: $font-weight-semibold;
}

.c-patient-card-header__info {
    flex-grow: 1;
}

.c-patient-card-header__info-list-item {
    color: #b1b1b1;
    font-size: 12px;
    line-height: 1.5em;
    font-weight: $font-weight-semibold;
    
    &:not(:last-of-type) {
        margin: 0 0 5px 0;
    }
}

.c-patient-card-header__btn-edit {
    position: absolute;
    top: 27px;
    right: 14px;
}

@media (max-width: 1439px) {
    .c-patient-card-header__name {
        font-size: 15px;
    }
}