.u-color-info {
    color: $color-info;
}

.u-color-success {
    color: $color-success;
}

.u-color-muted {
    color: rgba(#7D7D7D, 0.5);
}