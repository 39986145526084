@font-face {
    font-family: 'Muli';
    src: url('fonts/MuliRegular.eot');
    src: url('fonts/MuliRegular.eot') format('embedded-opentype'),
         url('fonts/MuliRegular.woff2') format('woff2'),
         url('fonts/MuliRegular.woff') format('woff'),
         url('fonts/MuliRegular.ttf') format('truetype'),
         url('fonts/MuliRegular.svg#MuliRegular') format('svg');
    font-weight: 400;
}

@font-face {
    font-family: 'Muli';
    src: url('fonts/MuliBold.eot');
    src: url('fonts/MuliBold.eot') format('embedded-opentype'),
         url('fonts/MuliBold.woff2') format('woff2'),
         url('fonts/MuliBold.woff') format('woff'),
         url('fonts/MuliBold.ttf') format('truetype'),
         url('fonts/MuliBold.svg#MuliBold') format('svg');
    font-weight: 700;
}

/* --- Poppins --- */
@font-face {
    font-family: 'Poppins';
    src: url('fonts/PoppinsRegular.eot');
    src: url('fonts/PoppinsRegular.eot') format('embedded-opentype'),
         url('fonts/PoppinsRegular.woff2') format('woff2'),
         url('fonts/PoppinsRegular.woff') format('woff'),
         url('fonts/PoppinsRegular.ttf') format('truetype'),
         url('fonts/PoppinsRegular.svg#PoppinsRegular') format('svg');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/PoppinsMedium.eot');
    src: url('fonts/PoppinsMedium.eot') format('embedded-opentype'),
         url('fonts/PoppinsMedium.woff2') format('woff2'),
         url('fonts/PoppinsMedium.woff') format('woff'),
         url('fonts/PoppinsMedium.ttf') format('truetype'),
         url('fonts/PoppinsMedium.svg#PoppinsMedium') format('svg');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/PoppinsSemiBold.eot');
    src: url('fonts/PoppinsSemiBold.eot') format('embedded-opentype'),
         url('fonts/PoppinsSemiBold.woff2') format('woff2'),
         url('fonts/PoppinsSemiBold.woff') format('woff'),
         url('fonts/PoppinsSemiBold.ttf') format('truetype'),
         url('fonts/PoppinsSemiBold.svg#PoppinsSemiBold') format('svg');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/PoppinsBold.eot');
    src: url('fonts/PoppinsBold.eot') format('embedded-opentype'),
         url('fonts/PoppinsBold.woff2') format('woff2'),
         url('fonts/PoppinsBold.woff') format('woff'),
         url('fonts/PoppinsBold.ttf') format('truetype'),
         url('fonts/PoppinsBold.svg#PoppinsBold') format('svg');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}




