.c-chat {
    min-height: 500px;
    height: calc(100vh - 55px);
    display: flex;
}

.c-chat__contacts-section {
    width: 356px;
    box-shadow: 2px 0 14px -5px rgba(0,0,0,0.12);
    flex-shrink: 0;
    flex-grow: 0;
    height: 100%;

    @media (max-width: 1439px) {
        width: 290px;
    }
}

.c-chat__main-section {
    width: 100%;
    flex-shrink: 1;
    height: 100%;
}

.c-chat__patient-section {
    height: 100%;
    width: 478px;
    flex-shrink: 0;
    flex-grow: 0;
    box-shadow: -2px 0 14px -5px rgba(0,0,0,0.12);
    overflow-y: auto;
    @include scrollbar(transparent, $color-info, 12px);

    @media (max-width: 1439px) {
        width: 360px;
    }
}

.c-chat.is-disabled {
    background: #fafafa;
    cursor: not-allowed;
}