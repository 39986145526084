.c-chat-observation {
    padding: 19px 15px 14px 0;
    border-bottom: 1px solid #F1F1F1;
}

.c-chat-observation__header {
    display: flex;
}

.c-chat-observation__date {
    font-size: 30px;
    line-height: 38px;
    color: #aeaeae;
    font-weight: $font-weight-semibold;
    width: 90px;
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
}

.c-chat-observation__event {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-shrink: 1;
}

.c-chat-observation__event-title {
    color: #aeaeae;
    font-size: 15px;
    line-height: 23px;
    font-weight: $font-weight-semibold;
}

.c-chat-observation__event-meta {
    font-size: 12px;
    line-height: 1.5em;
    color: #aeaeae;
    font-weight: $font-weight-semibold;
}

.c-chat-observation__body {
    padding: 15px 80px 0 65px;
}

.c-chat-observation__body-wrapper {
    background: #F8F8F8;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    position: relative;
    border-radius: 5px;
}

.c-chat-observation__title {
    color: #7D7D7D;
}

.c-chat-observation__content {
    color: #aeaeae;
}

.c-chat-observation__event-type {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    @include flex-center;

    .c-chat-observation__event-icon {
        width: 21px; 
        height: auto;
    }
}

.c-chat-observation.is-past {
    .c-chat-observation__event-type {
        background: $color-event-past;
    }
}

.c-chat-observation.is-incoming {
    .c-chat-observation__event-type {
        background: $color-event-incoming;
    }
}

.c-chat-observation.is-closest {
    .c-chat-observation__event-type {
        background: $color-event-closest;
    }
}
