.c-calendar-week-title {
    display: flex;
    border-bottom: 1px solid #EFEFEF;

    .title {
        height: 60px;
        text-transform: uppercase;
        text-align: center;
        color: #727272;
        line-height: 60px;
        font-weight: 600;
        font-size: 12px;
    }

    .c-calendar-week__day-col {
        border-color: transparent;
    }
}