.c-app-params-dictionary {
    padding: 10px 0 0;
}

.c-app-params-dictionary__box-header {
    min-height: 63px;
    padding-top: 22px;
    padding-bottom: 22px;
}

.c-app-params-dictionary__items {
    padding: 20px 70px 20px 70px;
}

.c-app-params-dictionary-item {
    position: relative;
}

.c-app-params-dictionary-item__not-changeable {
    height: 38px;
    flex-grow: 1;
    padding: 0 18px;
    line-height: 38px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #7D7D7D;
}

.c-app-params-dictionary-item__label {
    flex-shrink: 0;
    min-width: 80px;
}

.c-app-params-dictionary-item__btn {
    position: absolute;
    left: 100%;
    top: 0;
    @include btn-reset;
    height: 100%;
    padding: 0 5px;
}
