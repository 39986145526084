.o-textarea {
    font-size: 12px;
    line-height: 1.5em;
    color: #7D7D7D;
    font-weight: $font-weight-semibold;
    padding: 10px 18px;
    resize: none;
    outline: 0;
    width: 100%;

    &::placeholder {
        color: rgba(#4a4a4a, 0.2);
    }

    &[disabled] {
        cursor: not-allowed;
    }
}

// --- PRIMARY VARIANT ---
.o-textarea--primary {
    border-radius: 25px;
    background: #fff;
    border: 1px solid #D3D6DB;

    &:focus {
        box-shadow: 0 0 0 0.2em $color-info;
        border-color: $color-info;
    }

    &[disabled] {
        background-color: #F8F8F8;
    }
}