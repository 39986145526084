$font-family-primary: 'Poppins', sans-serif;
$font-family-secondary: 'Muli', sans-serif;

$font-color-primary: '#000';

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$top-bar-height: 55px;

$color-black: #000;
$color-success: #5BD95B;
$color-info: #60D1FE;
$color-secondary: #CACACA;
$color-link: $color-info;

$color-event-past: #D3D6DB;
$color-event-past-accent: #7D7D7D;
$color-event-closest: #60D1FE;
$color-event-closest-accent: #60D1FE;
$color-event-incoming: #ADECAD;
$color-event-incoming-accent: #5BD95B;
$color-event-rejected: #E4490F;

$bp-4xs: '(max-width: 359px)';
$bp-3xs: '(max-width: 409px)';
$bp-2xs: '(max-width: 459px)';
$bp-xs:  '(max-width: 575px)';
$bp-sm:  '(min-width: 576px)';
$bp-md:  '(min-width: 768px)';
$bp-lg:  '(min-width: 992px)';
$bp-xlg: '(min-width: 1200px)';
$bp-2lg: '(min-width: 1360px)';
$bp-3lg: '(min-width: 1440px)';
$bp-4lg: '(min-width: 1600px)';
$bp-5lg: '(min-width: 1920px)';

$z-index: (
    progress-spinner: 999999999,
    dropdown: 9999
);