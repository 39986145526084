
.c-chat-contact {
    display: flex;
    align-items: center;
    padding: 17px 16px 17px 18px;
    border-bottom: 1px solid #F1F1F1;
}

.c-chat-contact__avatar {
    margin: 0 16px 0 0;
}

.c-chat-contact__main {
    width: 100%;
    flex-shrink: 1;
}

.c-chat-contact__meta {
    flex-shrink: 0;
    padding: 0 0 0 18px;
}

.c-chat-contact__name {
    color: #727272;
    font-size: 17px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
}

.c-chat-contact__meta-time {
    @include flex-end-x;
    color: #727272;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
}

.c-chat-contact__meta-messages {
    @include flex-end-x;
    margin: 7px 0 0 0;
}

.c-chat-contact.is-disabled {
    cursor: not-allowed;
}

.c-chat-contact.is-selected {
    background: #efefef;
}