
.c-patient-activity-progress {
    position: relative;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    box-shadow: 0 5px 12px 0 rgba(0,0,0,0.11); 
}

.c-patient-activity-progress__full-ring {
    stroke: #ddd;
    stroke-width: 8;
    stroke-dasharray: 1 3;
    fill: transparent;
}

.c-patient-activity-progress__value-ring {
    stroke-width: 8;
    fill: transparent;
    transform: rotate(-90deg) translateX(-100%);
}

.c-patient-activity-progress__body,
.c-patient-activity-progress__svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.c-patient-activity-progress__body {
    @include flex-center;
    flex-direction: column;
    position: relative;
}

.c-patient-activity-progress__value {
    font-size: 20px;
    font-weight: $font-weight-medium;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
}

.c-patient-activity-progress__description {
    font-size: 8px;
    font-weight: $font-weight-medium;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    position: absolute;
    transform: translateY(130%);
}

.c-patient-activity-progress--success {
    .c-patient-activity-progress__value-ring {
        stroke: $color-success;
    }

    .c-patient-activity-progress__body {
        color: $color-success;
    }
}

.c-patient-activity-progress--info {
    .c-patient-activity-progress__value-ring {
        stroke: $color-info;
    }
}

