@mixin grid-alignment($bp_name: '') {
    @if ($bp_name != '') {
        .o-grid__row--justify-start\@#{$bp_name} {
            justify-content: flex-start;
        }

        .o-grid__row--justify-end\@#{$bp_name} {
            justify-content: flex-end;
        }

        .o-grid__row--justify-center\@#{$bp_name} {
            justify-content: center;
        }

        .o-grid__row--justify-between\@#{$bp_name} {
            justify-content: space-between;
        }

        .o-grid__row--align-start\@#{$bp_name} {
            align-items: flex-start;
        }

        .o-grid__row--align-end\@#{$bp_name} {
            align-items: flex-end;
        }

        .o-grid__row--align-center\@#{$bp_name} {
            align-items: center;
        }
    } @else {
        .o-grid__row--justify-start {
            justify-content: flex-start;
        }

        .o-grid__row--justify-end {
            justify-content: flex-end;
        }

        .o-grid__row--justify-center {
            justify-content: center;
        }

        .o-grid__row--justify-between {
            justify-content: space-between;
        }

        .o-grid__row--align-start {
            align-items: flex-start;
        }

        .o-grid__row--align-end {
            align-items: flex-end;
        }

        .o-grid__row--align-center {
            align-items: center;
        }
    }

}

@mixin grid-columns($bp_name: '') {
    @if ($bp_name != '') {
        .o-grid__item--auto\@#{$bp_name} {
            width: auto;
        }
    } @else {
        .o-grid__item--auto {
            width: auto;
        }
    }

    @for $c from 1 through $grid_columns {
        @if ($bp_name != '') {
            .o-grid__item--#{$c}\@#{$bp_name} {
                width: ($c / $grid_columns) * 100%;
            }
        } @else {
            .o-grid__item--#{$c} {
                width: ($c / $grid_columns) * 100%;
            }
        }

    }
}

@mixin grid-gaps($bp_name: '') {
    @each $g in $grid_gaps {
        @if ($bp_name != '') {
            .o-grid--gap-#{$g}\@#{$bp_name}  {
                > .o-grid__row {
                    margin: -#{$g / 2}px;

                    > .o-grid__item {
                        padding: #{$g / 2}px;
                    }
                }
            }

            .o-grid--gap-x-#{$g}\@#{$bp_name}  {
                > .o-grid__row {
                    margin-left: -#{$g / 2}px;
                    margin-right: -#{$g / 2}px;

                    > .o-grid__item {
                        padding-left: #{$g / 2}px;
                        padding-right: #{$g / 2}px;
                    }
                }
            }

            .o-grid--gap-y-#{$g}\@#{$bp_name}  {
                > .o-grid__row {
                    margin-top: -#{$g / 2}px;
                    margin-bottom: -#{$g / 2}px;

                    > .o-grid__item {
                        padding-top: #{$g / 2}px;
                        padding-bottom: #{$g / 2}px;
                    }
                }
            }
        } @else {
            .o-grid--gap-#{$g} {
                > .o-grid__row {
                    margin: -#{$g / 2}px;

                    > .o-grid__item {
                        padding: #{$g / 2}px;
                    }
                }
            }

            .o-grid--gap-x-#{$g}  {
                > .o-grid__row {
                    margin-left: -#{$g / 2}px;
                    margin-right: -#{$g / 2}px;

                    > .o-grid__item {
                        padding-left: #{$g / 2}px;
                        padding-right: #{$g / 2}px;
                    }
                }
            }

            .o-grid--gap-y-#{$g}  {
                > .o-grid__row {
                    margin-top: -#{$g / 2}px;
                    margin-bottom: -#{$g / 2}px;

                    > .o-grid__item {
                        padding-top: #{$g / 2}px;
                        padding-bottom: #{$g / 2}px;
                    }
                }
            }
        }

    }
}

@mixin grid-row-wrap($bp_name: '') {
    @if ($bp_name != '') {
        .o-grid__row--no-wrap {
            flex-wrap: nowrap;
        }
    } @else { 
        .o-grid__row--no-wrap\@#{$bp_name} {
            flex-wrap: nowrap;
        }
    
    }
}

.o-grid {
    width: 100%;
}

.o-grid__row {
    display: flex;
    flex-wrap: wrap;
}

.o-grid__item {
    width: 100%;
    flex-shrink: 1;
}

@include grid-alignment();

@include grid-columns();

@include grid-gaps();

@include grid-row-wrap();

@each $bp_name, $bp_query in $grid_breakpoints {
    @media #{$bp_query} {
        @include grid-alignment($bp_name);

        @include grid-columns($bp_name);

        @include grid-gaps($bp_name);

        @include grid-row-wrap($bp_name);
    }
}
