.o-errors {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}
  
.o-errors__error {
    margin: 5px 0;
    padding: 0 5px;
    font-size: 11px;
    font-weight: 600;
    color: #ff0033;
    line-height: 14px;
}
  