html body .p-calendar {
    height: 38px;
    width: 100%;
    max-width: 140px;
    font-family: $font-family-primary;
    border-radius: 25px;
    background: #fff;
    border: 1px solid #D3D6DB;
    display: flex;
    min-width: 0;
    overflow: hidden;
    
    &.p-calendar--size-sm {
        height: 32px;
    }
    
    &:not(.p-disabled).p-inputwrapper-focus {
        box-shadow: 0 0 0 0.2em $color-info;
        border-color: $color-info;
    }

    .p-inputtext {
        min-width: 85px;
        font-family: $font-family-primary;
        border-radius: 25px;
        flex-shrink: 1;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        line-height: 1.5em;
        color: #7D7D7D;
        padding: 0 4px 0 18px;
        @include flex-center-y();
        
        & {
            background: transparent;
            outline: 0;
            box-shadow: none;
            border: none;
        }

        &:hover, &:focus {
            &:not(.p-disabled),
            &:not([disabled]) {
                background: transparent;
                outline: 0;
                box-shadow: none;
                border: none;
            }
        }

        &::-webkit-input-placeholder { /* Edge */
            color: #7D7D7D;
        }
          
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #7D7D7D;
        }
          
        &::placeholder {
            color: #7D7D7D;
        }
    }
    
    .p-inputtext,
    .p-calendar-button.p-button.p-datepicker-trigger {
        &.p-disabled,
        &[disabled] {
            background: #F8F8F8;
            -webkit-text-fill-color: #7D7D7D;
            color: #7D7D7D;
            opacity: 1;

            &, & > * {
                cursor: not-allowed !important;
            }
        }
    }

    &.p-calendar-w-btn
    .p-datepicker-trigger.p-button {
        position: static;
        background: transparent;
        border: none;
        @include flex-center();
        padding: 0 11px 2px 8px;
        flex-shrink: 0;
        flex-grow: 0;
        border-left: 1px solid #D3D6DB;
        width: auto;

        &, &:hover, &:focus {
            background: transparent;
            outline: 0;
            box-shadow: none;
            border: none;
            border-left: 1px solid #D3D6DB;
        }

        &::after {
            content: '';
            width: 19px;
            height: 19px;
            background: url('../../images/ic_event.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .pi, .p-button-text {
            display: none;
        }
    }

    &.p-calendar-w-btn.p-calendar-timeonly
    .p-datepicker-trigger.p-button {
        &::after {
            width: 17px;
            height: 17px;
            background: url('../../images/ic_clock.svg');
        }
    }


    &.p-calendar-variant-filter {
        height: 26px;
        max-width: 121px;
        border: 1px solid #F1F1F1;
        
        .p-inputtext {
            min-width: 80px;
            padding: 0 1px 0 10px;
        }

        &.p-calendar-w-btn
        .p-datepicker-trigger.p-button {
            border-left-color: transparent;
        }
    }
}