.o-progress-spinner {
    @include flex-center();
    background: rgba(#fff, 0.8);
    overflow: hidden;
}

.o-progress-spinner--for-viewport {
    position: fixed;
    z-index: map-get($z-index, progress-spinner);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.o-progress-spinner--for-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
}