.c-hospital-add-modal {
    width: 580px;
    max-width: 100%;
}

.c-hospital-add-modal__label-col {
    width: 150px;
    flex-shrink: 0;
}

.c-hospital-add-modal__label-col--email {
    width: 85px;
}

.c-hospital-add-modal__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-hospital-add-modal__address,
.c-hospital-add-modal__type {
    padding-right: 65px;
}

.c-hospital-add-modal__emails,
.c-hospital-add-modal__phones {
    padding-right: 38px;
}

.c-hospital-add-modal__phones {
    padding-top: 10px;
}

.c-hospital-add-modal__phone-group {
    padding: 20px 0;
    margin: 0;
}

.c-hospital-add-modal__hours {
    padding-left: 20px;
    padding-right: 20px;
}

.c-copy-time-button {
    height: 38px;
    width: 100%;
    border-radius: 25px;
}

.c-hospital-add-modal__chat {
    padding-left: 20px;
    padding-right: 20px;
}

.c-hospital-add-modal__events {
    padding-left: 20px;
    padding-right: 20px;
}


.c-hospital-add-modal__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-hospital-add-modal__btn {
    min-width: 94px;
}
