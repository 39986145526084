.c-patient-recommendation-templates-form {
    width: 600px;
}

.c-patient-recommendation-templates-form__header {
    border-bottom: 1px solid #F1F1F1;
}

.c-patient-recommendation-templates-form__btn {
    min-width: 94px;
}