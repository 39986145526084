.c-patient-medicine-form__name {
    padding-left: 0;
    padding-right: 30px;
}

.c-patient-medicine-form__name-label-col {
    width: 135px;
    flex-shrink: 0;
}

.c-patient-medicine-form__name-input-col {
    width: 100%;
    flex-shrink: 1;
}   

.c-patient-medicine-form__dosage-btn {
    width: 85px;
}

.c-patient-medicine-form__dosage-days {
    padding: 0 18px 30px 18px;
    border-bottom: 1px solid #f1f1f1;
}

.c-patient-medicine-form__dosage-time {
    padding: 30px 18px 30px 18px;
    border-bottom: 1px solid #f1f1f1;

    .first-col {
        max-width: 105px;
    }

    .second-col {
        max-width: calc(100% - 105px);
    }

    .value-col {
        max-width: 70px;
    }

    .input-dosage {
        padding: 0;
        width: 38px;
        text-align: center;
    }

    .time-col {
        @media (max-width: 1439px) {
            margin-top: 10px;
        }
    }

    .p-calendar {
        max-width: 100px;
    }
    .p-calendar .p-inputtext {
        min-width: 61px;
    }
}

.c-patient-medicine-form__dosage-custom {
    padding: 30px 30px 0 18px;
}

.c-patient-medicine-form__taking-period {
    padding-left: 18px;
}

.c-patient-medicine-form__comments {
    padding-left: 20px;
    padding-right: 20px;
}

.c-patient-medicine-form__footer {
    padding: 30px 20px 0 20px;
    border-top: 1px solid #f1f1f1;
}

.c-patient-medicine-form__btn {
    min-width: 94px;
}