.c-forgot-password-modal {
    width: 480px;
    max-width: 100%;
}

.c-patient-add-modal__header {
    border-bottom: 1px solid #F1F1F1;
}

.c-forgot-password-modal__email {
    padding: 0 40px;
}

.c-forgot-password-modal__label-col {
    flex-shrink: 0;
}

.c-forgot-password-modal__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-forgot-password-modal__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-forgot-password-modal__btn {
    min-width: 94px;
}
