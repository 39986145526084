.o-modal {
    background: #fff;
    box-shadow: 0 38px 40px -20px rgba(0,0,0,0.3);
    border-radius: 0 0 6px 6px;
}

.o-modal--preview-page {
    width: 580px;
}

.o-modal__header {
    font-family: 'Muli', sans-serif;
    font-size: 22px;
    font-weight: $font-weight-medium;
    color: $color-info;
    line-height: 1.3;
    padding: 25px 20px;
    text-align: center;
}

.o-modal--preview-page__header {
    border-bottom: 1px solid #F1F1F1;
}

.o-modal__body {

}

.o-modal--preview-page__body {
    padding: 27px 0;
}

.o-modal--preview-page__viewport {
    width: 375px;
    margin: 0 auto;
    border: 1px solid #F1F1F1;
    border-radius: 10px;
    background-color: #FFFFFF;
    overflow: hidden;
}

.o-modal__footer {
    
}

.o-modal--preview-page__footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}