.c-report {
    padding: 15px 0 0;
}

.c-report__title {
    color: #727272;
    font-size: 14px;
    font-family: 'Muli';
    font-weight: bold;
    line-height: 1.2;
    padding: 12px 25px;
    min-height: 42px;
    border-bottom: 1px solid #F1F1F1;
}