.o-nav-tile {
    display: block;
    position: relative;
    height: 154px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 8px 15px -5px rgba(96,209,254,0), 0 8px 15px -8px rgba(0,0,0,0);
    transition: all 200ms ease-in-out;
    cursor: pointer;

    &, &:hover {
        color: #fff;
    }
}

.o-nav-tile__icon {
    position: absolute;
    right: 18px;
    top: 18px;
}

.o-nav-tile__title {
    font-size: 20px;
    line-height: 1.35em;
    font-weight: $font-weight-medium;
    position: absolute;
    bottom: 20px;
    left: 20px;
    max-width: 100px;
}

.o-nav-tile--info {
    background-color: $color-info;

    &:hover {
        box-shadow: 0 8px 15px -5px rgba($color-info, 0.3), 0 8px 15px -8px rgba(0,0,0,0.4);
    }   
}

.o-nav-tile--secondary {
    background-color: $color-secondary;

    &:hover {
        box-shadow: 0 8px 15px -5px rgba($color-secondary, 0.3), 0 8px 15px -8px rgba(0,0,0,0.4);
    }   
}

.o-nav-tile--success {
    background-color: $color-success;

    &:hover {
        box-shadow: 0 8px 15px -5px rgba($color-success, 0.3), 0 8px 15px -8px rgba(0,0,0,0.4);
    }  
}