.c-patient-calendar {
    overflow: auto;
    @include scrollbar(transparent, $color-info, 12px);
    padding: 0 0 30px 0;
}

.c-patient-calendar__wrapper {
    min-width: 500px;
}

.c-patient-calendar-month__name {
    margin: 0 0 9px;
    text-transform: capitalize;
    text-align: center;
    font-size: 13px;
    color: #727272;

    span {
        cursor: pointer;
    }
}

.c-patient-calendar-month__wrap {
    position: relative;

    .cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 40;
        cursor: pointer;
    }
}