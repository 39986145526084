
.c-patient-event-observations-form {
    padding: 30px 0 0 0;
}

.c-patient-event-observations-form__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-patient-event-observations-form__btn {
    min-width: 94px;
}

.c-patient-event-observations-form__content {
    padding-left: 16px;
    padding-right: 16px;
}