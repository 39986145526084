.c-hospital-event-preview__header {
    display: flex;
    align-items: flex-end;
    min-height: 58px;
    border-bottom: 1px solid #f1f1f1;
    padding: 9px 17px;
    font-size: 13px;
    line-height: 20px;
    font-weight: $font-weight-semibold;
    color: #727272;
}

.c-hospital-event-preview__body {
    display: flex;
    padding: 18px 0 26px 0;
    border-bottom: 1px solid #f1f1f1;
}

.c-hospital-event-preview__icon {
    width: 68px;
    height: 68px;
    position: relative;
    border-radius: 50%;
    margin: 0 20px 0 18px;
    flex-shrink: 0;
    @include flex-center();

    .c-patient-event-presence {
        right: -3px;
        bottom: -3px;
    }
}

.c-hospital-event-preview__name {
    color: #727272;
    font-size: 17px;
    line-height: 1.5em;
    font-weight: $font-weight-semibold;
    position: relative;
    padding: 0 20px 2px 0;
}

.c-hospital-event-preview__toolbox {
    flex-shrink: 0;
}

.c-hospital-event-preview__tool {
    margin: 0 0 0 15px;
}

.c-hospital-event-preview__info {
    flex-grow: 1;
}

.c-hospital-event-preview__info-list-item {
    color: #b1b1b1;
    font-size: 12px;
    line-height: 1.5em;
    font-weight: $font-weight-semibold;
    
    &:not(:last-of-type) {
        margin: 0 0 5px 0;
    }

    .user-link {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .status-label {
        color: #b1b1b1;

        &.is-confirmed {
            color: $color-event-incoming;
        }

        &.is-rejected {
            color: $color-event-rejected;
        }
    }
}

.c-hospital-event-preview__btn-edit {
    position: absolute;
    top: 0;
    right: 14px;
}

.c-hospital-event-preview__additional {
    margin: 7px 0 20px 0;
}

.c-hospital-event-preview__additional-item {
    display: flex;
    align-items: center;   
}


.c-hospital-event-preview__additional-item-text {
    color: #b1b1b1;
    font-size: 12px;
    line-height: 1.5em;
    font-weight: $font-weight-semibold;
    margin: 0 0 0 7px;
}

.c-hospital-event-preview {
    &.is-past .c-hospital-event-preview__icon {
        background: $color-event-past;
    }

    &.is-incoming .c-hospital-event-preview__icon {
        background: $color-event-incoming;
    }

    &.is-closest .c-hospital-event-preview__icon {
        background: $color-event-closest;
    }

    &.is-rejected .c-hospital-event-preview__icon {
        background: $color-event-rejected;
    }
}

@media (max-width: 1439px) {
    .c-hospital-event-preview__name {
        font-size: 15px;
    }
}