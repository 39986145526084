.c-app-params-event-form {
    width: 600px;
    max-width: 100%;

    .o-fieldset__legend-wrapper {
        padding: 13px 15px;
    }
}

.c-app-params-event-form__footer {
    padding: 10px 30px;
    border-top: 1px solid #f1f1f1;
}

.c-app-params-event-form__btn {
    min-width: 94px;
}

.c-app-params-event-form__type-fieldset,
.c-app-params-event-form__name-fieldset,
.c-app-params-event-form__duration-fieldset,
.c-app-params-event-form__icon-fieldset {
    padding-left: 15px;
    padding-right: 20px;
}

.c-app-params-event-form__label-col {
    flex-shrink: 0;
    min-width: 62px;
}

.c-app-params-event-form__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-app-params-event-form__input-col--duration {
    width: 100px;
}
