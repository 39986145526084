.o-repeater-row {
    display: flex;
    align-items: flex-end;
}

.o-repeater-row__content {
    flex-shrink: 1;
    flex-grow: 1;
}

.o-repeater-row__btn-container {
    @include flex-center();
    width: 50px;
    height: 38px;
    padding: 0 6px;
    flex-shrink: 0;
}

.o-repeater-row__btn-container--size-sm {
    width: 44px;
    height: 32px;

    .o-repeater-btn--add {
        .o-repeater-btn__icon {
            height: 32px;
        }
    }
}