.c-patient-graph {
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 29px -7px rgba(0,0,0,0.14);
    margin: 29px 0 6px;
    position: relative;
    min-height: 438px;
}

.c-patient-pulse-graph__view-dropdown {
    position: absolute;
    top: 19px;
    left: 16px;
}

.c-patient-pulse-graph__header {
    padding: 46px 0 5px 0;
}

.c-patient-pulse-graph__body {
    padding: 0 9px 10px 9px;
}


.c-patient-pulse-graph__left-nav-btn,
.c-patient-pulse-graph__right-nav-btn {
    position: absolute;
    bottom: 26px;
}

.c-patient-pulse-graph__left-nav-btn {
    left: 5px;
}

.c-patient-pulse-graph__right-nav-btn {
    right: 5px;
}

// Chartist
.c-patient-graph {
    .ct-point,
    .ct-line {
        stroke: $color-info;
    }

    .ct-line {
        stroke-width: 6px;
    }

    .ct-point {
        stroke-width: 16px;
    }
}

.c-patient-graph--blood_pressure {
    .ct-series-a .ct-point {
        stroke: $color-success;
    }

    .ct-series-b .ct-point {
        stroke: $color-info;
    }

    .ct-point {
        stroke-width: 10px;
    }
}

// Labels X axis
.c-patient-graph.week .ct-labels > *:nth-child(7),
.c-patient-graph.month .ct-labels > *:nth-child(3),
.c-patient-graph.year .ct-labels > *:nth-child(7) {
    span {
        position: relative;
        z-index: 1;
        color: #ffffff;
        width: auto !important;
        display: inline-block;

        &::before {
            display: block;
            content: '';
            width: 31px;
            height: 31px;
            position: absolute;
            left: 50%;
            top: -4px;
            transform: translateX(-50%);
            border-radius: 50%;
            background: #7c7c7c;
            z-index: -1;
        }
    }
}

.c-patient-graph.year .ct-labels > *:nth-child(7) {
    span {
        &::before {
            width: 36px;
        }
    }
}
