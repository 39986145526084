.c-patient-measurement-list-item {
    display: flex;
    justify-content: space-between;
    padding: 13px 30px;

    &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1; 
    }
}

.c-patient-measurement-list-item__date {
    color: #D3D6DB;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    letter-spacing: 0;
    line-height: 1.5em;
    text-transform: uppercase;
}

.c-patient-measurement-list-item__value {
    color: #7D7D7D;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    letter-spacing: 0;
    line-height: 1.5em;
    text-align: right;
}