.c-patient-interventions-table {
    position: relative;

    .p-dropdown .p-dropdown-label {
        &.p-inputtext::-webkit-input-placeholder {
            color: #dedede;
        }
        
        &.p-inputtext:-ms-input-placeholder {
            color: #dedede;
        }
        
        &.p-inputtext::placeholder {
            color: #dedede;
        }
    }
}

.c-patient-interventions-table__brackets {
    left: -15px;
}

.c-patient-interventions-table__menu-cell {
    width: 42px;
    text-align: right;
    padding-right: 0;
}

.c-patient-interventions-table__actions-cell {
    width: 80px;
    text-align: center;
}

.c-patient-interventions-table__to-edit {
    display: inline-block;
    min-height: 15px;
    width: 100%;
}

.c-patient-interventions-table__date-wrap {
    position: relative;
    display: grid;
    max-width: 262px;
    padding-right: 50px;
    grid-template-columns: repeat(auto-fill, minmax(66px, 1fr));
    column-gap: 6px;

    .date-form-actions {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -9px;
    }
}

.c-patient-interventions-table__date-errors {
    max-width: 220px;
}