.c-education {
    min-height: 100vh;
}

.c-education__main {
    flex-shrink: 1;
    flex-grow: 1;
}

.c-education__nav {
    margin: 14px 0 20px 0;
}

.c-education__management {
    flex-shrink: 0;
    flex-grow: 0;
    box-shadow: -2px 0 14px -5px rgba(0,0,0,0.12);
    min-height: 100%;
}