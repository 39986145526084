.o-data-group {
    display: flex;
    align-items: center;
    margin: 0 0 5px 0;

    &:last-of-type {
        margin: 0;
    }
}

.o-data-group__label {
    width: 100px;
    flex-shrink: 0;

    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    color: rgba(#7D7D7D, 0.5);
    margin: 0;
    text-align: right;
}

.o-data-group__value {
    padding: 0 0 0 12px;
    width: 100%;
    flex-shrink: 1;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    color: #7D7D7D;
    line-height: 1.5em;
    margin: 0;
}