.o-checkbox {
    position: relative;
    display: inline-block;
    cursor: default;
    flex-shrink: 0;
    margin: 0;
}

.o-checkbox__wrapper {
    @include flex-center();
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid $color-secondary;
    background: #fff;
    cursor: pointer;
    transition: all 50ms ease-in-out;
}

.o-checkbox__input {
    @include visually-hidden();
    left: 50%;
    top: 50%;
}

.o-checkbox__check {
    background: url('../../images/tick.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 8px;
    height: 8px;
    opacity: 0;
    transition: opacity 50ms ease-in-out;
}

.o-checkbox__input:checked {
    +.o-checkbox__wrapper {
        .o-checkbox__check {
            opacity: 1;
        }
    }
}

.o-checkbox__input:disabled {
    + .o-checkbox__wrapper {
        border-color: #D3D6DB;
        background-color: #D3D6DB;
        cursor: not-allowed;
    }
}


.o-checkbox__input[readonly] {
    + .o-checkbox__wrapper {
        cursor: default;
    }
}

.o-checkbox--success {
    .o-checkbox__input:checked:not(:disabled) + .o-checkbox__wrapper {
        border-color: $color-success;
        background-color: $color-success;
    }
}

// change label color on select
.o-checkbox--colorized {
    padding: 0 0 0 27px;
    line-height: 1;
    cursor: pointer;
    user-select: none;

    .o-checkbox__input:checked:not(:disabled) + .o-checkbox__wrapper + .o-label {
        color: $color-black;
    }

    .o-checkbox__wrapper {
        position: absolute;
        top: 2px;
        left: 0;
    }
}