.o-form-group {
    margin: 0 0 20px 0;
}

.o-form-group--inline {
    display: flex;
    align-items: center;
}

.o-form-group--top-sm {
    display: flex;

    .c-patient-card-form__label-col {
        margin-top: 7px;
    }
}

.o-form-group:last-of-type {
    margin: 0;
}

.o-form-group--gap-sm {
    margin: 0 0 7px 0;
}