.o-tab-btn--primary {
    font-size: 13px;
    line-height: 1.5em;
    padding: 11px 32px;
    font-weight: $font-weight-semibold;
    color: #727272;
    text-align: center;
    position: relative;
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
    }

    &.is-active {
        background: #fff;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        box-shadow: -2px 0 14px -5px rgba(0,0,0,0.15);
        z-index: 3;

        &::after {
            height: 3px;
            bottom: -3px;
            background: #fff;
            box-shadow: none;
         
        }
    }
}