@mixin flex($justify-content: flex-start, $align-items: flex-start, $flex-direction: row) {
    display: flex;
    justify-content: $justify-content;
    align-items: $align-items;
    flex-direction: $flex-direction;
}

@mixin flex-center-x() {
    display: flex;
    justify-content: center;
}

@mixin flex-center-y() {
    display: flex;
    align-items: center;
}

@mixin flex-start-y() {
    display: flex;
    align-items: flex-start;
}

@mixin flex-center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-end-x() {
    display: flex;
    justify-content: flex-end;
}

@mixin flex-end-y() {
    display: flex;
    align-items: flex-end;
}