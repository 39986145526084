.c-center-type-add-modal {
    width: 600px;
    max-width: 100%;
}

.c-center-type-add-modal__label-col {
    width: 150px;
    flex-shrink: 0;
}

.c-center-type-add-modal__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-center-type-add-modal__data {
    padding-right: 32px;
}

.c-center-type-add-modal__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-center-type-add-modal__btn {
    min-width: 94px;
}