.o-fieldset__legend {
    display: block;
    width: 100%;
}

.o-fieldset__legend-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: $font-weight-bold;
    color: #727272;
    line-height: 1.3em;
    padding: 13px 26px;
    border: 1px solid #F1F1F1;
    border-left: 0;
    border-right: 0;

}

.o-fieldset__body {
    padding: 30px 0;
}

.o-fieldset__clear-btn {
    font-size: 14px;
    color: $color-info;
    text-decoration: underline;
    margin: 0 0 0 auto;
}