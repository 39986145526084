.c-patient-measurements-header__wrapper {
    display: flex;
    margin: -6px;
}

.c-patient-measurements-header__activities,
.c-patient-measurements-header__measurements {
    display: flex;
    flex-grow: 1;
}


.c-patient-measurements-header__card {
    margin: 6px;
    flex-grow: 1;
}

.c-patient-measurements-header__card--measurement {
    max-width: 250px;
    min-width: 160px;
}

@media (max-width: 1919px) {
    .c-patient-measurements-header__wrapper {
        flex-wrap: wrap;
    }

    .c-patient-measurements-header__card--measurement {
        width: 200px;
    }
}