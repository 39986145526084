.o-progress-bar {
    background-color: #D3D6DB;
    border-radius: 4px;
    height: 8px;
}

.o-progress-bar__value-bar {
    background-color: #60D1FE;
    height: 100%;
    border-radius: 4px;
}