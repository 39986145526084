.c-remove-modal {
    width: 580px;
    max-width: 100%;
}

.c-remove-modal__header {
    border-bottom: 1px solid #F1F1F1;
}

.c-remove-modal__body {
    padding: 20px 10px;
}

.c-remove-modal__footer {
    padding: 10px 30px;
    border-top: 1px solid #F1F1F1;

    button {
        min-width: 94px;
    }
}