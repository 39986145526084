html body .fc {
    td.fc-head-container,
    .fc-body,
    td.fc-widget-content,
    .fc-row {
        border-color: #EFEFEF;
    }

    .fc-row {
        border: 0;
    }

    .fc-body .fc-row {
        min-height: 80px;
    }

    .fc-row.fc-widget-header {
        border-color: transparent;
    }

    .fc-day-header {
        font-weight: $font-weight-semibold;
        color: #727272;
        font-size: 12px;
        line-height: 1.5em;
        padding: 22px 10px;
        background: #fff;
        text-transform: uppercase;
        border: 0;
    }

    .fc-content-skeleton {
        position: absolute;
        top: 0;
        bottom: 0;

        table {
            height: 100%;
            vertical-align: bottom;
        }

        .fc-event-container {
            vertical-align: bottom;
            padding: 0 7px 7px 7px;
        }
    }

    .fc-day-top {
        padding: 15px 15px 0 0;

        &.fc-other-month {
            opacity: 0.5;
        }
    }

    .fc-day {
        &.fc-today {
            background: transparent;
        }

        &.fc-sat,
        &.fc-sun {
            background: rgba(96,209,254,0.1);
        }

        &.fc-other-month {
            background: #F8F7F8;
        }

     
    }

    .fc-day-number {
        color: #8A888A;
        font-size: 14px;
        line-height: 21px;
        font-weight: $font-weight-medium;
    }
}

// year view
html body .c-patient-calendar-month .fc {
    .fc-day-header {
        font-size: 8px;
        line-height: 10px;
    }

    .fc-body .fc-row {
        min-height: 46px;
    }

    .fc-day-top {
        padding: 5px 3px 0 0;
    }

    .fc-dayGrid-view .fc-week-number,
    .fc-dayGrid-view .fc-day-number {
        padding: 2px 2px 0;
    }

    .fc-day-number {
        font-size: 10px;
        line-height: 10px;
    }

    .fc-content-skeleton .fc-event-container {
        padding: 0 7px 1px 7px;
    }
}