@mixin scrollbar($track-color: #ddd, $thumb-color: #23c6c8, $width: 5px) {
    &::-webkit-scrollbar {
        width: $width;
        height: $width;
    }
  
    &::-webkit-scrollbar-track {
        background-color: $track-color;
        border-radius: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
        background-color: $thumb-color;
        border-radius: 5px;
    }
  }
  