.o-radio{
    position: relative;
    display: inline-block;
    cursor: default;
    flex-shrink: 0;
    margin: 0;
}

.o-radio__wrapper {
    @include flex-center();
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $color-secondary;
    background: #fff;
    cursor: pointer;
    transition: all 50ms ease-in-out;
}

.o-radio__input {
    @include visually-hidden();
    left: 50%;
    top: 50%;
}

.o-radio__check {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 50ms ease-in-out;
}

.o-radio__input:checked {
    +.o-radio__wrapper {
        .o-radio__check {
            opacity: 1;
        }
    }
}

.o-radio__input:disabled {
    + .o-radio__wrapper {
        border-color: #D3D6DB;
        background-color: #D3D6DB;
        cursor: not-allowed;

        .o-radio__check {
            border-color: #b7bcc4;
            background-color: #b7bcc4;
        }
    }
}


.o-radio--success {
    .o-radio__check {
        background-color: $color-success;
    }
}