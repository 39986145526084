
.c-app-params-event-icons {
    width: 202px;
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    border-collapse: collapse;
    table-layout: fixed;
    user-select: none;
}

.c-app-params-event-icons__icon {
    width: 40px;
    height: 40px;
    border: 1px solid #f1f1f1;

    &.is-selected {
        background: $color-info;
    }
}

.c-app-params-event-icons__icon-wrapper {
    height: 100%;
    width: 100%;
    @include flex-center;
    position: relative;

    img {
        position: absolute;
    }
}