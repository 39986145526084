.o-box {
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.1);
}

.o-box__header {
    border: 1px solid #F1F1F1;
    border-top-width: 2px;
    min-height: 48px;
    padding: 16px 16px 13px 16px;
    color: #727272;
    font-family: $font-family-secondary;
    font-size: 15px;
    font-weight: $font-weight-bold;
    letter-spacing: 0;
    line-height: 1.25em;
}

.o-box__footer {
    min-height: 48px;
    padding: 0 16px;
}