.c-chat-contacts {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.c-chat-contacts__header {
    padding: 20px 18px;
    flex-shrink: 0;
}

.c-chat-contacts__hospital-select {
    margin: 0 0 20px 0;
}

.c-chat-contacts__main {
    flex-shrink: 1;
    height: 100%;
    overflow-y: auto;
    @include scrollbar(transparent, $color-info, 12px);
}