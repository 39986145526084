.c-diet-preview-page {
    max-height: 700px;
    overflow-y: auto;
    padding: 0 0 20px;
}

.c-diet-preview-page__info {
    position: relative;
    height: 354px;

    .image {
        height: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .title {
        position: absolute;
        z-index: 1;
        left: 24px;
        right: 24px;
        bottom: 48px;
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.4;
    }
}

.c-diet-preview-page__block {
    width: 340px;
    border-radius: 10px;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);

    .sub-title {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: #60d1fe;
    }
}

.c-diet-preview-page__block--ingredients {
    position: relative;
    z-index: 2;
    padding: 20px 30px;
    margin-top: -40px;

    .ingredients {
        margin: 15px 0 0;

        li {
            position: relative;
            padding: 0 0 0 24px;
            line-height: 1.2;
            font-size: 14px;

            + li {
                margin: 5px 0 0;
            }

            &::before {
                position: absolute;
                left: 0;
                top: 6px;
                content: '';
                width: 5px;
                height: 5px;
                background: #60d1fe;
                border-radius: 50%;
            }
        }
    }
}

.c-diet-preview-page__block--preparation {
    margin-top: 16px;
    padding: 20px;
    font-size: 15px;
    line-height: 1.4;

    .sub-title {
        color: #5bd85b;
    }

    .text {
        margin: 15px 0 0;

        > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}