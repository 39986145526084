.c-patient-card-form {

}

.c-patient-card-form__label-col {
    width: 120px;
    flex-shrink: 0;
}

.c-patient-card-form__input-col {
    width: 100%;
    flex-shrink: 1;
}