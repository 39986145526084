.c-patient-event-list-item__header {
    font-size: 30px;
    line-height: 38px;
    color: #aeaeae;
    font-weight: $font-weight-semibold;
    width: 110px;
    padding: 0 0 0 18px;
    display: flex;
    align-items: center;
}

.c-patient-event-list-item__body {
    padding: 0 15px 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 200ms ease-in-out;
    width: 100%;
    flex-shrink: 1;
}

.c-patient-event-list-item__content {
    padding: 0 20px 0 0;
}

.c-patient-event-list-item__title {
    color: #aeaeae;
    font-size: 15px;
    line-height: 23px;
    font-weight: $font-weight-semibold;
}

.c-patient-event-list-item__main {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.c-patient-event-list-item__info {
    font-size: 12px;
    line-height: 1.5em;
    color: #aeaeae;
    font-weight: $font-weight-semibold;
}

.c-patient-event-list-item__more {
    padding: 20px 0 15px;
}

.c-patient-event-list-item__toolbox {
    display: flex;
    align-items: center;
}

.c-patient-event-list-item__tool {
    margin: 0 13px;
}

.c-patient-event-list-item__tool--event-type {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;
    @include flex-center;

    .c-patient-event-list-item__icon {
        width: 21px; 
        height: auto;
    }
}

.c-patient-event-list-item__observations {
    background: #F8F8F8;
    padding: 10px 15px;
    margin: 0 45px 0 110px;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: -13px;
        right: 45px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 13px solid #F8F8F8;
    }
}

.c-patient-event-list-item__observations-title {
    color: #7D7D7D;
}

.c-patient-event-list-item__observations-content {
    color: #aeaeae;
}

.c-patient-event-list-item__observation {
    padding: 5px 0;
    &:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }
}

.c-patient-event-list-item__confirm {
    padding: 15px 20px 0 0;
}

.c-patient-event-list-item__confirm-btn {
    width: 190px;
    padding: 4px 2px;
}

.c-patient-event-list-item.is-past {
    .c-patient-event-list-item__tool--event-type {
        background: $color-event-past;
    }
}

.c-patient-event-list-item.is-incoming {
    .c-patient-event-list-item__tool--event-type {
        background: $color-event-incoming;
    }
}

.c-patient-event-list-item.is-closest {
    .c-patient-event-list-item__tool--event-type {
        background: $color-event-closest;
    }
}

.c-patient-event-list-item.is-rejected {
    .c-patient-event-list-item__tool--event-type {
        background: $color-event-rejected;
    }
}

.c-patient-event-list-item.is-selected {
    &.is-past {
        .c-patient-event-list-item__title {
            color: $color-event-past-accent;
        }
    }

    &.is-incoming {
        .c-patient-event-list-item__title {
            color: $color-event-incoming-accent;
        }
    }

    &.is-closest {
        .c-patient-event-list-item__title {
            color: $color-event-closest-accent;
        }
    }

    .c-patient-event-list-item__main {
        background: rgba(96,209,254,0.05);
    }
    .c-patient-event-list-item__more {
        background: rgba(96,209,254,0.05);
    }
}

@media (max-width: 1439px) {
    .c-patient-event-list-item__header {
        width: 70px;
    }
}