html body .p-datatable {
    .p-datatable-thead > tr > th {
        &, &.p-sortable-column {
            background: #fff;
            color: #727272;	
            font-family: 'Muli', sans-serif;
            font-size: 13px;	
            font-weight: $font-weight-bold;	
            line-height: 16px;
            vertical-align: bottom;
            padding: 11px 16px;
            text-align: left;
            border: 0;
            box-shadow: none;

            .pi {
                color: #848484;
            }
        }

        &.p-sortable-column:hover {
            background: #e0e0e0;
            color: #727272;	

            .pi {
                color: #848484;
            }
        }
    }

    .p-datatable-tbody > tr > td {
        background: #fff;
        color: #727272;	
        font-family: 'Muli', sans-serif;
        font-size: 13px;	
        font-weight: $font-weight-regular;
        line-height: 16px;
        padding: 11px 16px;
        text-align: left;
        border: 0;
        vertical-align: middle;
        height: 48px;
    }

    .p-datatable-tbody tr:nth-child(odd) > td {
        background-color: #f7fcff;
    }

    .p-datatable-tfoot {
        display: none;
    }
}