.c-chat-form {
    
    padding: 15px 18px 21px 18px;
    border-top: 1px solid #f1f1f1;
}

.c-chat-form__inputs-wrap {
    display: flex;
    align-items: center;
    padding: 10px 0 0;
}

.c-chat-form__input-container {
    padding: 0 14px;
    position: relative;
    flex: 1 1 auto;
}

.c-chat-form__media {
    display: inline-block;
    background: #ddd;
    border-radius: 3px;
    padding: 3px 0 3px 6px;
    overflow: hidden;
}

.c-chat-form__media-in {
    @include flex-center-y;
    justify-content: flex-start;
    font-weight: $font-weight-medium;
    font-size: 12px;
    line-height: 1.3em;
}

.c-chat-form__media-text {
    flex-shrink: 1;
    max-width: calc(100% - 24px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.c-chat-form__media-remove {
    @include flex-center-x;
    width: 24px;
    font-size: 14px;
    flex-shrink: 0;
}

.c-chat-form__sms {
    margin: 0 0 0 14px;
}
