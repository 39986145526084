// header
.c-patient-survey-answers-header {
    @include flex-end-y;
    color: #727272;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    padding: 9px 17px 9px 17px;
    min-height: 58px;
    border-bottom: 1px solid #F1F1F1;
}

// title
.c-patient-survey-answers-title {
    padding: 18px 17px 25px;
    font-weight: 600;
}

.c-patient-survey-answers-title__title {
    color: #727272;
    font-size: 17px;
    line-height: 1.5;
}

.c-patient-survey-answers-title__date {
    margin: 6px 0 0;
    color: rgba(125,125,125, 0.6);
    font-size: 12px;
}

// answer
.c-patient-survey-answer {
    padding: 24px 22px 30px 30px;
    border-top: 1px solid #F1F1F1;
    color: #7D7D7D;
}

.c-patient-survey-answer__title {
    margin: 0 0 23px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.5;
}

.c-patient-survey-answer__question {
    font-size: 12px;
    line-height: 1.5;
}

.c-patient-survey-answer__answer {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}

.c-patient-survey-answer__answer--is-bad {
    color: #FF0000;
}
.c-patient-survey-answer__answer--is-normal {
    color: #7D7D7D;
}
.c-patient-survey-answer__answer--is-good {
    color: #5BD95B;
}