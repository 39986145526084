
.c-education-message-form {
    width: 890px;
}

.c-education-message-form__label-col {
    flex-shrink: 0;
    width: 126px;
}

.c-education-message-form__meal-fieldset,
.c-education-message-form__content-fieldset {
    padding-left: 30px;
    padding-right: 30px;
}

.c-education-message-form__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-education-message-form__footer {
    @include flex-center-y;
    padding: 11px 30px;
    border-top: 1px solid #F1F1F1
}

.c-education-message-form__btn {
    min-width: 94px;
}

.c-education-message-form__wysiwyg {
    .ql-container.ql-snow {
        height: 250px;
    }
}

