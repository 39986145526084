.c-patient-hospital-events-preview-modal {
    width: 1392px;
    max-width: 100%;
    padding: 0 20px;
}

.c-patient-hospital-events-preview-modal__footer {
    padding: 10px 0;   
    border-top: 1px solid #F1F1F1;
}

.c-patient-hospital-events-preview-modal__btn {
    min-width: 94px;
}