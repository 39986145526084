.c-table-box-wrap {
    flex: 1 1 100%;
}

.c-table-box-wrap--events {
    padding-top: 20px;
}

.c-table-box-wrap--app-params {
    padding-top: 20px;
}

.c-table-box-full-height-wrap {
    flex-grow: 1;
    min-height: 0;
    height: 100%;

    .c-table-box {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }

    .o-table-responsive {
        flex-grow: 1;
        min-height: 0;
        display: flex;
        max-height: 100%;
    }

    .o-datatable {
        flex-grow: 1;
        min-height: 296px;
    }

    .o-datatable__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .o-datatable__body {
        flex-grow: 1;
        min-height: 0;
    }
}

.c-table-box {
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.1);
    border-top: 2px solid #F1F1F1;
}
.c-tabl-box--addressee {
    border-top-width: 1px;
    box-shadow: none;
}

.c-table-box__header {
    padding: 13px;
    border-bottom: 1px solid #F1F1F1;
    color: #727272;
    font-family: $font-family-secondary;
    font-size: 15px;
    font-weight: $font-weight-bold;
    letter-spacing: 0;
    line-height: 1.25em;
    position: relative;
}


.c-table-box__toolbox {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}