.c-patient-table {
    min-width: 1700px;
}

.c-patient-table__treatment-program {
    width: 245px;
}

.c-patient-table__email {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}