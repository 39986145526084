.o-table {
    color: #727272;
    font-family: $font-family-secondary;
    font-size: 13px;
    line-height: 1.25em;
    width: 100%;
    table-layout: auto;
}


.o-table__td,
.o-table__th {
    height: 48px;
    padding: 2px 16px;
    vertical-align: middle;
}

.o-table__th {
    font-weight: $font-weight-bold;
}

.o-table__td {
    font-weight: $font-weight-regular;
}


.o-table__tr:nth-child(odd) {
    background: #f7fdff;
}

