.c-patient-event-presence {
    width: 22px;
    height: 22px;
    position: absolute;
    z-index: 2;
    bottom: -6px;
    right: -10px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #fff;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}