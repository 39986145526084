.c-education-lesson-preview-modal {
    width: 580px;
}

.c-education-lesson-preview-modal__body {
    padding: 27px 0;
}

.c-education-lesson-preview-modal__header {
    border-bottom: 1px solid #F1F1F1;
}

.c-education-lesson-preview-modal__footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-education-lesson-preview-modal__btn {
    min-width: 94px;
}
