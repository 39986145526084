.c-patient {
    display: flex;
}
.c-patient__card-section {
    width: 356px;
    box-shadow: 2px 0 14px -5px rgba(0,0,0,0.12);
    flex-shrink: 0;
    flex-grow: 0;
    transition: width 0.3s ease 0s;
}

.c-patient-card-wrap {
    position: relative;
    width: 356px;
    transition: transform 0.3s ease 0s;
}
.c-patient__card-section--hide {
    @media (max-width: 1919px) {
        width: 0 !important;

        .c-patient-card-wrap {
            transform: translateX(-100%);
        }
    }
}

.c-patient__nav {
    margin: 0 0 40px 0;
}

.c-patient__calendar-section,
.c-patient__recommendations-and-medicines-section,
.c-patient__diseases-and-interventions-section,
.c-patient__measurements-section,
.c-patient__surveys-section {
    padding: 24px 45px;
    flex-shrink: 1;
    flex-grow: 1;
}

.c-patient__recommendations-and-medicines-section {
    overflow-x: hidden;
}

.c-patient__calendar-section {
    overflow: hidden;
}

.c-patient__measurements-section {
    overflow: hidden;
}

.c-patient__management-section {
    width: 478px;
    flex-shrink: 0;
    flex-grow: 0;
    box-shadow: -2px 0 14px -5px rgba(0,0,0,0.12);
}

.c-patient__medicines {
    margin: 0 0 20px 0;
}

.c-patient__interventions {
    margin: 20px 0 0 0;
}

@media (max-width: 1439px) {
    .c-patient__card-section {
        width: 290px;
    }
    .c-patient-card-wrap {
        width: 290px;
    }
    .c-patient__management-section {
        width: 360px;
    }

    .c-patient__calendar-section,
    .c-patient__measurements-section, 
    .c-patient__recommendations-and-medicines-section,
    .c-patient__diseases-and-interventions-section,
    .c-patient__surveys-section {
        padding: 24px 25px;
    }


}

.c-chat__patient-section {
    .c-patient-card-wrap {
        width: 100%;
    }
}
