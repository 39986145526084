.c-hospital {
    display: flex;
    min-height: calc(100vh - 55px);
}

.c-hospital__calendar-section {
    padding: 0 45px 24px;
    flex-shrink: 1;
    flex-grow: 1;
}

.c-hospital__management-section {
    width: 478px;
    flex-shrink: 0;
    flex-grow: 0;
    box-shadow: -2px 0 14px -5px rgba(0,0,0,0.12);
}

@media (max-width: 1439px) {
    .c-hospital__management-section {
        width: 360px;
    }

    .c-hospital__calendar-section {
        padding: 0 25px 24px;
    }
}