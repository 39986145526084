.c-patient-weight-graph {
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 29px -7px rgba(0,0,0,0.14);
    margin: 35px 0 0 0;
    position: relative;
}

.c-patient-weight-graph__view-dropdown {
    position: absolute;
    top: 19px;
    left: 16px;
}

.c-patient-weight-graph__header {
    padding: 46px 0 5px 0;
}

.c-patient-weight-graph__body {
    padding: 0 9px 10px 9px;
}


.c-patient-weight-graph__left-nav-btn,
.c-patient-weight-graph__right-nav-btn {
    position: absolute;
    bottom: 26px;
}

.c-patient-weight-graph__left-nav-btn {
    left: 5px;
}

.c-patient-weight-graph__right-nav-btn {
    right: 5px;
}

// Chartist
.c-patient-weight-graph {
    .ct-series-a .ct-point,
    .ct-series-a .ct-line {
        stroke: $color-info;
    }

    .ct-series-a .ct-line {
        stroke-width: 6px;
    }

    .ct-series-a .ct-point {
        stroke-width: 16px;
    }
}