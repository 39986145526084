.c-patient-card-toggle-btn {
    position: absolute;
    top: 40px;
    right: 0;
    transform: translateX(100%);
    height: 100px;
    width: 22px;
    padding: 35px 4px 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 2px 0 7px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;

    @media (min-width: 1920px) {
        display: none;
    }
    
    .arrows {
        position: absolute;
        top: 15px;
        left: 4px;

        &.hide {
            transform: rotate(180deg);
        }
    }

    .text {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
        min-height: 52px;
        text-align: center;
    }
}