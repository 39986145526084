.c-login-form {
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
}

.c-login-form__form-group {
    margin: 0 0 30px 0;

    &:last-of-type {
        margin: 0;
    }
}

.c-login-form__label {
    font-size: 13px;
}

.c-login-form__link {
    display: block;
    text-align: center;
    color: $color-info;
    font-size: 15px;
    line-height: 1.5em;
    font-weight: $font-weight-semibold;
    margin: 13px 0 0 0;

    &:hover {
        color: $color-info;
        text-decoration: underline;
    }
}

.c-login-form__btn {
    margin: 65px 0 0 0;
    width: 100%;
    height: 65px;
    border-radius: 32px;
    box-shadow: 0 15px 25px 0 rgba(0,0,0,0.08);
    font-size: 20px;
} 