html body .p-autocomplete {
    width: 100%;

    &.p-autocomplete-dd {
        .p-autocomplete-input {
            padding: 0 57px 0 18px;
        }
    }

    .p-autocomplete-label {
        flex-shrink: 1;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        font-family: $font-family-primary;
        line-height: 1.5em;
        color: #7D7D7D;
        background: transparent;
        padding: 0 18px;
        @include flex-center-y();
    }

    .p-autocomplete-input {
        height: 38px;
        width: 100%;
        display: flex;
        min-width: 0;
        padding: 0 18px;
        font-family: $font-family-primary;
        font-weight: $font-weight-semibold;
        font-size: 12px;
        line-height: 1.5em;
        border-radius: 25px;
        border: 1px solid #D3D6DB;
        color: #7D7D7D;
        background: #fff;

        &:not(.p-disabled):hover {
            border-color: #D3D6DB;
        }

        &:not(.p-disabled):focus {
            box-shadow: 0 0 0 0.2em $color-info;
            border-color: $color-info;
        }

        &.p-disabled {
            background: #F8F8F8;
            -webkit-text-fill-color: #7D7D7D;
            opacity: 1;

            &, & > * {
                cursor: not-allowed !important;
            }

            ~ .p-autocomplete-dropdown {
                cursor: not-allowed !important;
                opacity: 1;
            }
        }
    }

    &.p-autocomplete--size-sm {
        .p-autocomplete-input {
            height: 32px;
        }
    }

    &.p-autocomplete-dd {
        .p-autocomplete-dropdown {
            @include flex-center();
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            padding: 0 16px 0 13px;
            width: auto;
            background: transparent;
            border: none;
            border-left: 1px solid #D3D6DB;
            outline: none;

            &:hover,
            &:focus {
                border-left: 1px solid #D3D6DB;
                box-shadow: none;
            }

            &::after {
                content: '';
                width: 9px;
                height: 6px;
                background: url('../../images/arrow_down_blue.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .pi {
                display: none;
            }

            .p-button-text {
                display: none;
            }
        }
    }

    &.p-autocomplete-variant-filter {
        .p-autocomplete-label {
            padding: 0 11px;
            font-family: 'Muli';
            font-size: 13px;
            font-weight: normal;
            color: #727272;
        }

        .p-autocomplete-input {
            height: 26px;
            padding: 0 57px 0 11px;
            font-family: 'Muli';
            font-weight: normal;
            font-size: 13px;
            border: 1px solid #F1F1F1;
            color: #727272;

            &:not(.p-disabled):hover {
                border-color: #F1F1F1;
            }
    
            &:not(.p-disabled):focus {
                box-shadow: none;
                border-color: #F1F1F1;
            }

            &.p-disabled {
                -webkit-text-fill-color: #727272;
            }
        }

        &.p-autocomplete-dd {
            .p-autocomplete-dropdown {
                border-left-color: transparent;
    
                &:hover,
                &:focus {
                    border-left-color: transparent;
                }
    
                &::after {
                    background-image: url('../../images/arrow_down.svg');
                }
            }
        }
    }
}