.c-patient-list-filters-modal {
    width: 580px;
    max-width: 100%;
}

.c-patient-list-filters-modal__relational-operators {
    max-width: 350px;
}

.c-patient-list-filters-modal__treatment-program,
.c-patient-list-filters-modal__age,
.c-patient-list-filters-modal__gender {
    padding: 0 40px;
}

.c-patient-list-filters-modal__treatment-program {
    .treatment-program-item {
        margin: 0 0 20px 0;
    }
}


.c-patient-list-filters-modal__btn {
    min-width: 94px;
}
