html body .p-dropdown {
    height: 38px;
    width: 100%;
    font-family: $font-family-primary;
    border-radius: 25px;
    background: #fff;
    border: 1px solid #D3D6DB;
    display: flex;
    width: 100%;
    min-width: 0;

    &.p-dropdown-size-sm {
        height: 32px;
    }


    &:not(.p-disabled).p-focus {
        box-shadow: 0 0 0 0.2em $color-info;
        border-color: $color-info;
    }

    &:not(.p-disabled):hover {
        border-color: #D3D6DB;
    }

    &.p-disabled {
        background: #F8F8F8;
        opacity: 1;

        &, & > * {
            cursor: not-allowed !important;
        }
    }

    .p-dropdown-label {
        flex-shrink: 1;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        font-family: $font-family-primary;
        line-height: 1.5em;
        color: #7D7D7D;
        background: transparent;
        padding: 0 18px;
        @include flex-center-y();
    }
    
    .p-dropdown-trigger-icon {
        display: none !important;
    }
 
    
    .p-dropdown-trigger {
        @include flex-center();
        background: transparent;
        width: auto;
        padding: 0 16px 0 13px;
        height: 100%;
        position: static;
        flex-shrink: 0;
        border-left: 1px solid #D3D6DB;
        border-radius: 0;
    
        &::after {
            content: '';
            width: 9px;
            height: 6px;
            background: url('../../images/arrow_down_blue.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    
    &.p-dropdown-variant-filter {
        height: 26px;
        border: 1px solid #F1F1F1;

        &:not(.p-disabled).p-focus {
            box-shadow: none;
            border-color: #F1F1F1;
        }

        &:not(.p-disabled):hover {
            border-color: #F1F1F1;
        }

        .p-dropdown-label {
            padding: 0 11px;
            font-family: 'Muli';
            font-size: 13px;
            font-weight: normal;
            color: #727272;
        }

        .p-dropdown-trigger {
            border-left-color: transparent;

            &::after {
                background-image: url('../../images/arrow_down.svg');
            }
        }
    }
}
