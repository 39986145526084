.c-app-params-treatment-program {

}
.c-app-params-treatment-program__label {
    min-width: 80px;;
}

.c-app-params-treatment-program__main {
    padding: 20px 70px 0 70px;
}
.c-app-params-treatment-program__states {
    padding: 20px 70px 20px 70px;
}

.c-app-params-treatment-program__box-header {
    min-height: 63px;
    padding-top: 22px;
    padding-bottom: 22px;
}