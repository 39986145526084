
.u-text-uppercase {
    text-transform: uppercase;
}

.u-text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.u-text-font-normal {
    font-weight: 400;
}