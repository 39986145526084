.app {
    filter: blur(0);
    transition: filter 200ms ease-in-out;
}

.ReactModal__Body--open {
    .app {
        filter: blur(3px);
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    overflow-y: scroll;
    @include scrollbar(transparent, $color-info, 12px);
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close{ 
    opacity: 0;
}