.o-button-group {
    display: flex;
}

.o-button-group__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-size: 12px;
    font-family: $font-family-primary;
    font-weight: $font-weight-semibold;
    line-height: 1.5;
    border-radius: 3px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    height: 35px;
    border-radius: 17.5px;
    border-width: 1px;
    border-style: solid;
    text-decoration: none;

    &, &:focus {
        outline: 0;
    }

    &:hover {
        text-decoration: none;
    }

    &[disabled],
    &.is-disabled {
        cursor: not-allowed;
    }

    &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }

    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.o-button-group--info {
    .o-button-group__button {
        border-color: $color-info;
        color: $color-info;
        background: #fff;

       
        &.is-active {
            background: $color-info;
            color: #fff;
        }
    }
}