.c-report-bonuses-values {
    display: flex;
    flex-direction: column;
    @media(min-width: 992px) {
        height: 100%;
    }
}

.c-report-bonuses-values__item {
    display: flex;
    align-items: center;
    min-height: 198px;
    padding: 0 20px 0 46px;
    border-top: 1px solid #F1F1F1;
    color: #727272;
    font-family: 'Muli';
    font-weight: 600;
    @media(min-width: 992px) {
        flex: 0 0 50%;
        max-height: 50%;
    }

    .title {
        font-size: 14px;
        line-height: 1;
        clear: both;
    }

    .value {
        display: inline-block;
        font-size: 54px;
        line-height: 68px;
        position: relative;
        padding: 0 38px 0 0;
    }

    .value__currency {
        position: absolute;
        right: 0;
        bottom: 12px;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        color: #727272;
    }
}

.c-report-bonuses-values__item--first {
    @media(min-width: 992px) {
        border-top: none;
    }

    .value {
        color: #5BD95B;
    }
}

.c-report-bonuses-values__item--second {
    .value {
        color: #60D1FE;
    }
}