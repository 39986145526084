.c-education-subchapter-card-lesson {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 0 11px 0;
}

.c-education-subchapter-card-lesson__name {
    color: #7D7D7D;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5em;
    margin: 0 0 0 16px;
    width: 100%;
    flex-shrink: 1;
}

.c-education-subchapter-card-lesson__menu,
.c-education-subchapter-card-lesson__actions {
    flex-shrink: 0;
}

.c-education-subchapter-card-lesson.is-selected {
    .c-education-subchapter-card-lesson__name {
        color: $color-info;
    }
}