.c-patient-recommendation-form {
    font-size: 13px;
    line-height: 1.5em;
    color: #7d7d7d;
    font-weight: $font-weight-semibold;
}

.c-patient-recommendation-form__body {
    display: flex;
}

.c-patient-recommendation-form__menu {
    display: flex;
    justify-content: flex-end;
    width: 42px;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 4px 0 0 0;
}

.c-patient-recommendation-form__marker {
    @include flex-center-y;
    border: 1px solid $color-info;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 4px 20px 0 20px;
}

.c-patient-recommendation-form__content {
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 0 20px 0 0;
}

.c-patient-recommendation-form__content-input {
    outline: 0;
    width: 100%;
    padding: 3px;
    margin: -3px;
    border-radius: 3px;

    &:focus {
        box-shadow: 0 0 0 0.2em $color-info;
    }
}

.c-patient-recommendation-form__footer {
    @include flex-end-x;
    padding: 10px 30px 0 16px;
}