.c-education-lesson-preview {
    width: 375px;
    margin: 0 auto;
    border: 1px solid #F1F1F1;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 40px 26px;
}

.c-education-lesson-preview__name {
    color: #4A4A4A;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    margin: 0 0 17px 0;
}

.c-education-lesson-preview__text {
    color: #4A4A4A;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
}