.c-patient-medicines-taking {}

.c-patient-medicines-taking__header {
    display: flex;
    align-items: flex-end;
    color: #727272;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    padding: 9px 17px 9px 17px;
    min-height: 58px;
    border-bottom: 1px solid #F1F1F1;

    .c-calendar-header {
        margin: 0;
    }
}

.c-patient-medicines-taking__list {
    > *:nth-child(2n) {
        background: rgba(96,209,254,0.05);
    }

    .list-title,
    .list-row {
        display: flex;
        height: 30px;
    }

    .list-title {
        font-size: 13px;
        font-weight: 600;
        color: #bcbcbc;
        align-items: flex-end;
        padding: 0 0 6px;
    }

    .list-row {
        align-items: center;

        .list-cel {
            font-size: 13px;
            color: #7D7D7D;
            line-height: 30px;

            &--1 {
                font-size: 20px;
                letter-spacing: 0.2px;
                font-weight: 600;
            }
        }
    }

    .list-cel {
        flex: 0 0 26%;
        max-width: 26%;
        text-align: center;

        &--1 {
            flex: 0 0 22%;
            max-width: 22%;
        }
    }
}