.c-calendar-week {
    border: 1px solid #EFEFEF;
}

.c-calendar-week__extra-space {
    display: flex;
    flex-wrap: nowrap;
}
.c-calendar-week__extra-space--before {
    border-bottom: 1px solid #EFEFEF;
}
.c-calendar-week__extra-space--after {
    border-top: 1px solid #EFEFEF;
}

.c-calendar-week__body {
    display: flex;
    flex-wrap: nowrap;
}

.c-calendar-week__hours-col {
    flex: 0 0 8.16%;
    max-width: 8.16%;
}

.c-calendar-week__hours-cell {
    text-align: center;
    padding: 21px 4px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #EFEFEF;
    }
}

.c-calendar-week__day-col {
    flex: 0 0 13.12%;
    max-width: 13.12%;
    border-left: 1px solid #EFEFEF;
    position: relative;

    .not-working {
        background: rgba(241,241,241,0.4);
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    }
}
.c-calendar-week__day-col--not-working {
    background: rgba(241,241,241,0.4);
}

.c-calendar-week__day-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #cfcfcf;

    &:not(:last-child) {
        border-bottom: 1px solid #EFEFEF;
    }
}

.c-calendar-week__events-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;

    .not-working {
        background: rgba(241,241,241,0.4);
    }
}

.c-calendar-week__event-wrap {
    position: absolute;
    left: 0;
    right: 0;
}

.c-calendar-week__event-free {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 12.5px;
    background: #F1F1F1;
}

// calendar in modal
.c-calendar-week--in-modal {
    .c-patient-calendar-event-group--hospital-calendar {
        cursor: default;
    }
    
    .c-calendar-week__event-free {
        cursor: pointer;
    
        &:hover {
            background: #5BD95B;
        }
    }
}

// calendar on page
.c-calendar-week--on-page {}