.c-patient-event-form__label-col {
    flex-shrink: 0;
}

.c-patient-event-form__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-patient-event-form__data {
    padding-right: 20px;
    
    .c-patient-event-form__label-col {
        width: 100px;
    }
}

.c-patient-event-form__recurrence {
    padding-right: 30px;

    .c-patient-event-form__label-col {
        width: 100px;
    }
}

.c-patient-event-form__confirmation {
    padding-left: 26px;
}

.c-patient-event-form__remider {
    padding-left: 26px;

    .label-reminder-num {
        padding: 0 20px 0 40px;
    }

    .input-reminder {
        width: 38px;
        padding: 0 4px;
        text-align: center;
    }

    .label-reminder-day {
        padding: 0 0 0 10px;
    }
}

.c-patient-event-form__notes {
    padding-left: 30px;
    padding-right: 25px;
}

.c-patient-event-form__bonus {
    padding-right: 20px;
}

.c-patient-event-form__bonus-item {
    margin: 0 0 20px 0;

    &:last-of-type {
        margin: 0;
    }
    
    .o-data-group__label {
        width: 133px;
    }
}

.c-patient-event-form__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-patient-event-form__btn {
    min-width: 94px;
}