.c-patient-graph-nav-btn {
    padding: 2px 5px;
    user-select: none;
}

.c-patient-graph-nav-btn__arrow-icon {
    width: 11px;
    height: auto;
}

.c-patient-graph-nav-btn--right {
    transform: rotate(180deg);
}