
.c-chat-item__wrapper {
    display: flex;
}

.c-chat-item__content {
    border-radius: 18px;
    max-width: 260px;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: $font-weight-medium;
    letter-spacing: -0.31px;
    line-height: 1.5em;
}

.c-chat-item__image {
    max-width: 100%;
    height: auto;
}

.c-chat-item__status {
    margin: 4px 0 8px;
    color: #bdbdbd;
    line-height: 1;
    font-size: 11px;
}

.c-chat-item--text.c-chat-item--incoming {
    text-align: left;

    .c-chat-item__wrapper {
        justify-content: flex-start;
    }

    .c-chat-item__content {
        background: $color-info;
        padding: 9px 42px 8px 16px;
    }

    &.c-chat-item--top {
        .c-chat-item__content {
            border-bottom-left-radius: 0;
        }
    }

    &.c-chat-item--center {
        .c-chat-item__content {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    &.c-chat-item--bottom {
        .c-chat-item__content {
            border-top-left-radius: 0;
        }
    }
}

.c-chat-item--text.c-chat-item--outgoing {
    text-align: right;
    
    .c-chat-item__wrapper {
        justify-content: flex-end;
    }

    .c-chat-item__content {
        background: $color-success;
        padding: 9px 16px 8px 42px;
    }

    &.c-chat-item--top {
        .c-chat-item__content {
            border-bottom-right-radius: 0;
        }
    }

    &.c-chat-item--center {
        .c-chat-item__content {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &.c-chat-item--bottom {
        .c-chat-item__content {
            border-top-right-radius: 0;
        }
    }
}

.c-chat-item--image {
    .c-chat-item__content {
        max-width: 400px;
        border: 1px solid #ddd;
        border-radius: 0;
    }
}

.c-chat-item--image.c-chat-item--incoming {
    text-align: left;

    .c-chat-item__wrapper {
        justify-content: flex-start;
    }
    
}

.c-chat-item--image.c-chat-item--outgoing {
    text-align: right;
    
    .c-chat-item__wrapper {
        justify-content: flex-end;
    }
}