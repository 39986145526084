.o-label {
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    color: rgba(#7D7D7D, 0.5);
    margin: 0;
}

.o-label--left {
    padding: 0 20px 0 0;
    text-align: right;
}

.o-label--right {
    padding: 0 0 0 20px;
    text-align: left;
}

.o-label--center {
    padding: 0 20px;
    text-align: center;
}

.o-label--top-center {
    padding: 0 20px 4px 20px;
    text-align: center;
    display: block;
}