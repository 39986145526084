.c-bonus-add-modal {
    width: 880px;
    max-width: 100%;
}

.c-bonus-add-modal__label-col {
    width: 150px;
    flex-shrink: 0;
}

.c-bonus-add-modal__input-col {
    width: 100%;
    max-width: calc(100% - 150px);
    flex-shrink: 1;
}

.c-bonus-add-modal__params-data {
    padding-right: 32px;
}

.c-bonus-add-modal__label-type-2 {
    @media (max-width: 991px) {
        width: 130px;
        text-align: right;
    }
}

.c-bonus-add-modal__col-type-2 {
    flex: 1 1 auto;
    @media (min-width: 992px) {
        flex: 1 1 153px;
        max-width: 153px;
    }
}


.c-bonus-add-modal__conditions-wrap {
    padding-left: 150px;
    margin: 0 0 20px 0;
}

.c-bonus-add-modal__condition-label-col {
    flex: 0 1 auto;
}

.c-bonus-add-modal__condition-input-col {
    flex: 0 0 70px;
    width: 70px;
}


.c-bonus-add-modal__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-bonus-add-modal__btn {
    min-width: 94px;
}

.c-bonus-add-modal__wysiwyg {
    .ql-container.ql-snow {
        height: 250px;
    }
}