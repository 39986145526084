.c-patient-nav {
    position: relative;
    z-index: 50;

    @media(max-width: 1599px) {
        
    }

    .o-button-group {
        @media (max-width: 1599px) {
            position: absolute;
            top: 40px;
            left: 0;
            flex-direction: column;
        }
    }

    .o-button-group__button {
        @media(max-width: 1599px) {
            border-radius: 0;
            border-left: 1px solid $color-info;
        }

        &:first-child {
            @media(max-width: 1599px) {
                border-top-left-radius: 17.5px;
                border-top-right-radius: 17.5px;
            }
        }

        &:last-child {
            @media(max-width: 1599px) {
                border-bottom-left-radius: 17.5px;
                border-bottom-right-radius: 17.5px;
            }
        }

        &:not(:first-child) {
            @media(max-width: 1599px) {
                border-top: 0;
            }
        }
    }
}

.c-patient-nav__link {
    min-width: 130px;
}