.c-diet-add-modal {
    width: 880px;
    max-width: 100%;
}

.c-diet-add-modal__label-col {
    width: 150px;
    flex-shrink: 0;
}

.c-diet-add-modal__input-col {
    width: 100%;
    max-width: calc(100% - 150px);
    flex-shrink: 1;
}

.c-diet-add-modal__personal-data,
.c-diet-add-modal__hospital {
    padding-right: 32px;
}

.c-diet-add-modal__ingredients {
    padding-right: 24px;

    .o-repeater-row {
        align-items: flex-start;
    }
}

.c-diet-add-modal__text {
    padding-left: 30px;
    padding-right: 30px;
}

.c-diet-add-modal__assign-for-data {
    padding-left: 50px;
    padding-right: 40px;
}

.c-assign-for-label {
    color: #727272;
}

.c-diet-add-modal__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-diet-add-modal__btn {
    min-width: 94px;
}

.c-diet-add-modal__wysiwyg {
    .ql-container.ql-snow {
        height: 250px;
    }
}