.o-panel__header {
    width: 100%;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    color: #727272;
    line-height: 1.3em;
    padding: 13px 14px 13px 18px;
    border: 1px solid #F1F1F1;
    border-left: 0;
    border-right: 0;
}

.o-panel__body {
    padding: 14px;
}