body {
    .p-dropdown-panel.select-tree {
        .p-dropdown-items {
            .p-dropdown-item {
                padding: 0;

                &:nth-child(2n+1) {
                    background-color: #fff;
                }

                &:nth-child(2n) {
                    background-color: #f7fdff;
                }

                &.p-highlight {
                    color: #333;
                }
            }

            .p-dropdown-item-group {
                &.p-highlight {
                    color: #333;
                }
            }
        }
    }
}

.select-tree__item {
    position: relative;
    min-height: 48px;
    padding: 8px 10px 8px 75px;
    display: flex;
    align-items: center;

    &.selected {
        .circle {
            &::after {
                opacity: 1;
            }
        }
    }

    .circle {
        @include flex-center();
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        left: 6px;
        top: 50%;
        margin-top: -8px;
        border: 1px solid $color-secondary;
        font-size: 0;

        &::after {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $color-success;
            opacity: 0;
            transition: opacity 50ms ease-in-out;
        }
    }
}

.select-tree__item--title {
    padding-left: 34px;
}

.select-tree__item--branch {
    .branch {
        position: absolute;
        top: 50%;
        left: 38px;
        line-height: 0;
        font-size: 0;

        &::after{
            display: block;
            content: '';
            width: 25px;
            position: absolute;
            left: 3px;
            bottom: 0;
            border-bottom: 1px dashed rgba(74, 74, 74, 0.5);
        }

        &::before {
            display: block;
            content: '';
            height: 46px;
            position: absolute;
            left: 0;
            bottom: 0;
            border-left: 1px dashed rgba(74, 74, 74, 0.5);
        }
    }

    .branch--first-on-level {
        &::before {
            height: 34px;
        }
    }
}