.u-text-center {
    text-align: center;
}

.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align: right;
}

.u-text-justify {
    text-align: justify;
}

.u-text-justify-center {
    text-align: justify;
    text-align-last: center;
}
