.c-patient-measurements-history__header {
    display: flex;
    align-items: flex-end;
    min-height: 58px;
    border-bottom: 1px solid #f1f1f1;
    padding: 9px 17px;
    font-size: 13px;
    line-height: 20px;
    font-weight: $font-weight-semibold;
    color: #727272;
}

.c-patient-measurements-history__body {
    @include scrollbar(transparent, $color-info, 12px);
    height: calc(100vh - 113px);
    overflow-x: auto;
}

.c-patient-measurements-history__no-data {
    padding: 20px;
    text-align: center;
    font-size: 22px;
    line-height: 2em;
    color: #4A4A4A;
    font-weight: 500;
    letter-spacing: -0.7px;
}