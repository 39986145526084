.c-calendar-header-arrow-btn {
    @include flex-center;
    user-select: none;
    
    &.is-disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.c-calendar-header-arrow-btn--prev {
    margin: 0 15px 0 10px;
}

.c-calendar-header-arrow-btn--next {
    margin: 0 10px 0 15px;
}