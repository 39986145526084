.c-relational-operator {
    @include flex-center-y();
}

.c-relational-operator__operator {
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    color: rgba(#7D7D7D, 0.5);
    padding: 0 9px;
}

.c-relational-operator__input {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}