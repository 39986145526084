.o-badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: $font-weight-semibold;
    line-height: 1em;
    height: 18px;
    width: 100%;
    border-radius: 13px;
    position: relative;
}


.o-badge--success {
    background: $color-success;
    color: #fff;
}

.o-badge--info {
    background: $color-info;
    color: #fff;
}

.o-badge--secondary {
    background: $color-secondary;
}

.o-badge--rounded {
    width: 18px;
    border-radius: 50%;
}

.o-badge--size-md {
    height: 26px;
    font-size: 13px;

    &.o-badge--rounded {
        width: 26px;
    }
}