.c-patient-activities-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.1);
    padding: 14px 10px 16px 10px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -28px;
        right: 0;
        width: calc(33.33% + 10px);
        height: 24px;
        clip-path: polygon(50% 24px, 0 0, 100% 0);
        opacity: 0;
        background-color: #60D1FE;
        transition: opacity 200ms ease-in-out;
    }
}

.c-patient-activities-card__header {
    color: #727272;
    font-size: 12px;
    font-weight: $font-weight-medium;
    line-height: 1.5em;
    margin: 0 0 31px 0;
    text-align: center;
}

.c-patient-activities-card__body {
    display: flex;
}

.c-patient-activities-card__activity-card {
    margin: 0 15px;
    flex-grow: 1;
}

.c-patient-activities-card.is-selected {
    &::after {
        opacity: 0.3;
    }
}