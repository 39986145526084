
.o-menu-bars {
    display: inline-block;
    width: 8px;
}

.o-menu-bars__bar {
    width: 100%;
    height: 1px;
    background: #D3D6DB;
    margin: 2px 0;
}