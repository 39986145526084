.c-education-subchapter-card-subjects-assign {
    padding-left: 25px;
    padding-top: 15px;
}

.c-education-subchapter-card-subjects-assign__info {
    color: #4A4A4A;
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.5em;
    margin: 39px 0 0 0;
}