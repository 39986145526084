body, html {
    font-family: $font-family-primary;
    color: $font-color-primary;
    font-size: 14px;
    overflow-x: hidden;
}

html body {
    font-family: $font-family-primary;
}

* {
    box-sizing: border-box;
    font-family: $font-family-primary;
    color: $font-color-primary;
}

input,
textarea,
button,
select,
label,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
    color: inherit;
}

table {
    border-collapse: collapse;
}

img {
    vertical-align: middle;
}

[role="button"] {
    cursor: pointer;
}