.u-flex-center {
    @include flex-center();
}

.u-flex-center-y {
    @include flex-center-y();
}

.u-flex-start-y {
    @include flex-start-y();
}

.u-flex-center-x {
    @include flex-center-x();
}

.u-flex-end-x {
    @include flex-end-x();
}