.o-time-range {
    @include flex-center-y();
}

.o-time-range__separator {
    padding: 0 6px;
    flex-shrink: 0;
    font-family: $font-family-primary;
    font-weight: $font-weight-semibold;
    font-size: 12px;
    color: #7D7D7D;
}