.c-top-bar {
    height: $top-bar-height;
    box-shadow: 0 2px 14px -5px rgba(0,0,0,0.12);
}

.c-top-bar__wrapper,
.c-top-bar__section,
.c-top-bar__logo,
.c-top-bar__buttons {
    height: 100%;
}

.c-top-bar__wrapper,
.c-top-bar__logo,
.c-top-bar__buttons {
    @include flex-center-y();
}

.c-top-bar__wrapper {
    justify-content: space-between;
    padding: 0 0 0 55px;
    margin: 0 auto;
}

.c-top-bar__section {
    display: flex;
}

.c-top-bar__logo {
    padding: 2px 55px 0 0;
}

.c-top-bar__buttons {
    margin: 0 0 0 20px;
}