.c-survey-addressee-modal {
    display: flex;
    flex-direction: column;
    width: 880px;
    max-width: 100%;
    height: calc(100vh - 80px);
}

.c-survey-addressee-modal__center {
    width: 100%;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #727272;
    line-height: 1.3em;
    padding: 13px 26px;
    border-top: 1px solid #F1F1F1;
}

.c-survey-addressee-modal__footer {
    padding: 10px 30px;
    border-top: 1px solid #F1F1F1;
}

.c-survey-addressee-modal__btn {
    min-width: 94px;
}