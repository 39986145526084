.u-flex-shrink-0 {
    flex-shrink: 0;
}

.u-flex-shrink-1 {
    flex-shrink: 1;
}

.u-flex-shrink-2 {
    flex-shrink: 2;
}

.u-flex-grow-0 {
    flex-grow: 0;
}

.u-flex-grow-1 {
    flex-grow: 1;
}

.u-flex-grow-2 {
    flex-grow: 2;
}