.c-survey-add-modal {
    width: 880px;
    max-width: 100%;
}

.c-survey-add-modal__label-col {
    width: 150px;
    flex-shrink: 0;
}

.c-survey-add-modal__label-frequency-col {
    width: 276px;
    flex-shrink: 0;
}

.c-survey-add-modal__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-survey-add-modal__options,
.c-survey-add-modal__ask,
.c-survey-add-modal__alerts {
    padding-right: 32px;
}

.c-survey-add-modal__start-date-type {
    max-width: 308px;
}

.c-survey-add-modal__threshold-col,
.c-survey-add-modal__decrease-col {
    max-width: 104px;
}

.c-survey-add-modal__decrease-label-col {
    width: 120px;
}

.c-survey-add-modal__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-survey-add-modal__btn {
    min-width: 94px;
}



.c-patient-add-modal__treatment-program {
    padding-right: 20px;
}

.c-patient-add-modal__educational-issues {
    padding-right: 40px;
    padding-left: 40px;
}

.c-patient-add-modal__hospital {
    padding-right: 65px;
}


.c-patient-add-modal__pesel-col {
    display: flex;
}

.c-patient-add-modal__phone-code-input {
    width: 106px;
    margin: 0 8px 0 0;
}

.c-patient-add-modal__info {
    margin: 15px 0 -20px 0;
    padding: 0 65px;
    font-size: 10px;
    font-weight: $font-weight-medium;
    line-height: 1.5em;
    text-align: right;
    color: #4A4A4A;
}

.c-patient-add-modal__info--educational-issues {
    margin-top: 45px;
    text-align: center;
}
