.c-education-subchapter-card-subjects-edit-item {
    display: flex;
    font-size: 13px;
    line-height: 1.5em;
    color: #7d7d7d;
    font-weight: $font-weight-semibold;
    padding: 8px 0 8px 25px;
}

.c-education-subchapter-card-subjects-edit-item__menu {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 4px 0 0 0;
}

.c-education-subchapter-card-subjects-edit-item__marker {
    @include flex-center-y;
    background: $color-info;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 4px 20px 0 9px;
}

.c-education-subchapter-card-subjects-edit-item__name {
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 224px;
}


.c-education-subchapter-card-subjects-edit-item__name-input {
    outline: 0;
    padding: 3px;
    margin: -3px;
    border-radius: 3px;

    &:focus {
        box-shadow: 0 0 0 0.2em $color-info;
    }
}

.c-education-subchapter-card-subjects-edit-item__actions {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 0 0 15px;
}