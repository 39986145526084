.c-chat-items-block {
    display: flex;
    align-items: flex-end;

    &:not(:last-of-type) {
        margin: 0 0 8px;
    }
}

.c-chat-items-block--incoming {
    padding: 0 0 14px;
}

.c-chat-items-block__avatar {
    flex-shrink: 0;
    margin: 0 8px -14px 0;
}

.c-chat-items-block__items {
    flex-shrink: 1;
    width: 100%;
}

.c-chat-items-block__item {
    &:not(:last-of-type) { 
        margin: 0 0 3px 0; 
    }
}

.c-chat-items-block__new {
    position: relative;
    font-size: 12px;
    text-align: center;
    color: red;

    span {
        position: relative;
        z-index: 1;
        padding: 0 5px;
        background: #fff;
    }

    &::before {
        display: block;
        position: absolute;
        top: 50%;
        content: '';
        height: 1px;
        width: 100%;
        background: red;
    }
}