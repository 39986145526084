.c-patient-diseases-table {
    position: relative;
}

.c-patient-diseases-table__brackets {
    left: -15px;
}

.c-patient-diseases-table__menu-cell {
    width: 42px;
    text-align: right;
    padding-right: 0;
}

.c-patient-diseases-table__name-cell {
    min-width: 170px;
}

.c-patient-diseases-table__actions-cell {
    width: 80px;
    text-align: center;
}

.c-patient-diseases-table__to-edit {
    display: inline-block;
    min-height: 15px;
    width: 100%;
}

.c-patient-diseases-table__date-wrap {
    position: relative;
    display: grid;
    max-width: 262px;
    padding-right: 50px;
    grid-template-columns: repeat(auto-fill, minmax(66px, 1fr));
    column-gap: 6px;

    .date-form-actions {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -9px;
    }
}

.c-patient-diseases-table__date-errors {
    max-width: 220px;
}