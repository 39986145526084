.c-app-params-treatment-program-new-state {
    position: relative;
    height: 38px;
}

.c-app-params-treatment-program-new-state__label {
    flex-shrink: 0;
    min-width: 80px;
}

.c-app-params-treatment-program-new-state__btn {

    @include btn-reset;
    margin: 0 0 0 75px;
    height: 100%;
    padding: 0 5px;
}


.c-app-params-treatment-program-new-state__btn-icon {
    height: 100%;
}