
.o-tabs--primary {
    background: #f8f8f8;
    padding: 10px 0 0 0;
    overflow: hidden;

    .o-tabs__nav {
        display: flex;
        overflow: hidden;
    }

    .o-tabs__content {
        position: relative;
        z-index: 2;;
        background: #fff;
        box-shadow: -2px 0 14px -5px rgba(0,0,0,0.15);
    }
}


