.c-time-input {
    display: flex;
    height: 38px;
    width: 100%;
    max-width: 123px;
    padding: 0;
    font-weight: $font-weight-semibold;
    font-family: $font-family-primary;

}

.c-time-input__input {
    flex-shrink: 1;
    flex-grow: 1;
    font-weight: $font-weight-semibold;
    font-family: $font-family-primary;
    appearance: none;
    font-size: 12px;
    color: #7D7D7D;
    padding: 0 0 0 18px;
    height: 100%;
    width: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: rgba(#4a4a4a, 0.2);
    }

    &input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    &[disabled] {
        cursor: not-allowed;
        -webkit-text-fill-color: #7D7D7D;
        color: #7D7D7D;
        opacity: 1;
    }
}

.c-time-input__append {
    @include flex-center();
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 11px 0 10px;
}

.c-time-input--primary {
    border-radius: 25px;
    background: #fff;
    border: 1px solid #D3D6DB;

    .c-time-input__append {
        border-left: 1px solid #D3D6DB; 
    }
}

.c-time-input.is-disabled {
    background-color: #F8F8F8;
}

.c-time-input.is-disabled {
    &, & > * {
        cursor: not-allowed;
    }
}

.c-time-input.is-focus {
    box-shadow: 0 0 0 0.2em $color-info;
    border-color: $color-info;
}