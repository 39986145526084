.c-patient-card-panel__info-list {
    margin: 20px 0 0 0;
}

.c-patient-card-panel__info-list-item {
    font-size: 12px;	
    font-weight: $font-weight-semibold;
    line-height: 1.5em;

    &:not(:last-of-type) {
        margin: 0 0 5px 0;
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    .tooltip-wrap {
        min-width: 16px;
    }
}