.c-user-add-modal {
    width: 580px;
    max-width: 100%;
}

.c-user-add-modal__label-col {
    width: 150px;
    flex-shrink: 0;
}

.c-user-add-modal__input-col {
    width: 100%;
    flex-shrink: 1;
}

.c-user-add-modal__personal-data,
.c-user-add-modal__hospital {
    padding-right: 65px;
}

.c-user-add-modal__footer {
    padding: 10px 30px;   
    border-top: 1px solid #F1F1F1;
}

.c-user-add-modal__btn {
    min-width: 94px;
}
