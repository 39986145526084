.c-education-compendium-table-form {
    @include flex-center-y;
    margin-top: 4px;
    height: 20px;
    overflow: visible;
}

.c-education-compendium-table-form__input {
    flex-shrink: 1;
    max-width: 220px;
}

.c-education-compendium-table-form__actions {
    flex-shrink: 0;
    margin: 0 0 0 15px;
}