.c-dashboard-header2__logo {
    text-align: center;
    margin: 0 0 24px 0;
}

.c-dashboard-header2__logo-img {
    width: 169px;
    height: auto;
}

.c-dashboard-header2__title {
    text-align: center;
    font-size: 44px;
    letter-spacing: 1px;
    color: #4a4a4a;

    strong {
        font-weight: $font-weight-bold;
    }
}