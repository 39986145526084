.c-patient-recommendation-templates {
    border: 1px solid #F8F8F8;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 11px 30px -10px rgba(0,0,0,0.21);
    padding: 12px 15px;
    max-width: 300px;
}

.c-patient-recommendation-templates__header {
    color: #727272;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;  
    margin: 0 0 2px 0;
}

.c-patient-recommendation-templates__body {
    color: #727272;
    font-size: 13px;
    line-height: 1.5em;
}

.c-patient-recommendation-templates__title {
    margin: 0 30px 0 0;
}

.c-patient-recommendation-templates__link {
    color: $color-info;
    text-decoration: underline;
}

.c-patient-recommendation-templates__item {
    position: relative;
    padding: 0 0 0 30px;
    margin: 12px 0;

    &::before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #727272;
        position: absolute;
        left: 10px;
        top: 8px;
    }
}