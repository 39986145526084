.c-report-bonuses-filters {
    padding: 43px 40px 43px 26px;

    @media(min-width: 992px) {
        border-right: 1px solid #F1F1F1;
    }
}

.c-report-bonuses-filters__item {
    display: flex;
    align-items: center;
    margin: 0 0 17px;
    color: #727272;

    &:last-of-type {
        margin: 0;
    }

    .label-col {
        flex: 0 0 88px;
        max-width: 88px;
        padding: 0 14px 0 0;
        font-size: 14px;
        font-family: 'Muli';
        font-weight: 700;
        line-height: 1;
    }

    .input-col {
        flex: 1 1 auto;
    }
    .input-col--range {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        @media(min-width: 992px) {
            flex-direction: row;
            align-items: center;    
        }
    }

    .input-col__range-label {
        flex: 1 0 auto;
        max-width: 121px;
        padding: 7px 10px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }
}

.c-report-bonuses-filters__btn {
    display: block;
    width: 94px;
    margin: 17px auto 0;
}