.o-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    font-size: 12px;
    font-family: $font-family-primary;
    font-weight: $font-weight-semibold;
    line-height: 1.5;
    border-radius: 3px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    height: 35px;
    border-radius: 17.5px;
    margin: 0;

    &, &:focus {
        outline: 0;
    }


    // &[disabled],
    // &.is-disabled {
    
    // }
}

.o-btn__icon {
    margin: 0 6px 0 0;
    max-height: 18px;
    width: auto;
}

// --- VARIANTS ---

// --- SUCCESS VARIANT ---
.o-btn--success {
    background: $color-success;
    border: 1px solid $color-success;
    color: #fff;

    &:not([disabled]):not(.is-disabled):hover {
        background: darken($color-success, 10%);
    }
}

// --- LIGHT VARIANT ---
.o-btn--light {
    background: #fff;
    border: 1px solid #D3D6DB;
    color: #D3D6DB;

    &:not([disabled]):not(.is-disabled):hover {
        background: #D3D6DB;
        color: #fff;
    }
}

// --- INFO VARIANT ---
.o-btn--info {
    background: $color-info;
    border: 1px solid $color-info;
    color: #fff;

    &:not([disabled]):not(.is-disabled):hover {
        background: darken($color-info, 10%);
    }
}

// --- ROUNDED ---
.o-btn--rounded {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

// --- SIZES ---
.o-btn--size-sm {
    height: 31px;

    &.o-btn--rounded {
        width: 31px;
    }
}

.o-btn--size-xs {
    height: 26px;

    &.o-btn--rounded {
        width: 26px;
        height: 26px;
    }
}
