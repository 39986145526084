.c-patient-recommendations-edit__header {
    @include flex-end-y;
    color: #727272;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    padding: 9px 17px 9px 17px;
    min-height: 58px;
    border-bottom: 1px solid #F1F1F1;
}

