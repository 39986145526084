.c-chat-image-preview {
    @include flex-center;
}

.c-chat-image-preview__wrapper {
    max-width: 100%;
}

.c-chat-image-preview__image {
    max-width: 100%;
    height: auto;
}