.c-report-statistics-filters {
    margin: 11px 0 0;
}

.c-hospital-grid-item,
.c-voivodeship-grid-item {
    flex: 1 1 382px;
    max-width: 382px;
}

.c-report-statistics-filters__item {
    display: flex;
    align-items: center;
    padding: 11px 0;
    color: #727272;

    .label-col {
        flex: 0 0 auto;
        padding: 0 14px 0 0;
        font-size: 14px;
        font-family: 'Muli';
        font-weight: 700;
        line-height: 1;
    }

    .input-col {
        flex: 1 1 auto;
    }
}
.c-report-statistics-filters__item--hospital {
    max-width: 382px;
}