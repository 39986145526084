
.o-repeater-btn {
    &[disabled], 
    &.is-disabled {
        cursor: not-allowed;
    }
}
.o-repeater-btn--add {
    .o-repeater-btn__icon {
        height: 38px;
        width: auto;
    }
   
}

.o-repeater-btn--remove {
    .o-repeater-btn__icon {
        height: 14px;
        width: auto;
    }
}