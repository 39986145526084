.c-app-params-treatment-program-state {
    position: relative;
}

.c-app-params-treatment-program-state__label {
    flex-shrink: 0;
    min-width: 80px;
}


.c-app-params-treatment-program-state__btn {
    position: absolute;
    left: 100%;
    top: 0;
    @include btn-reset;
    height: 100%;
    padding: 0 5px;
}
