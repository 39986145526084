.c-chat-user-avatar {
    width: 50px;
    height: 50px;
    background: $color-info;
    border-radius: 50%;
    @include flex-center();
    flex-shrink: 0;
}

.c-chat-user-avatar__img {
    width: 19px;
    height: auto;
}