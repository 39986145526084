.c-patient-medicines-table__show-taking {
    margin: -10px 0 -10px 0;
}

.c-patient-medicines {
    .o-box__body {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        @include scrollbar(transparent, $color-info, 12px);
    }
}

.c-patient-medicines-table {
    position: relative;
    min-width: 500px;
}

.c-patient-medicines-table__tr {
    opacity: 0.7;

    &.is-selected {
        opacity: 1;
    }
}

.c-patient-medicines-table__menu-cell {
    width: 42px;
    text-align: right;
    padding-right: 0;
}