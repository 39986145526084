body a {
    color: $color-link;
    text-decoration: none;
    cursor: pointer;
  }
body a:hover {
    color: #0c9dd8;
    text-decoration: none;
}
body a:active {
    color: #0c9dd8;
}
