.c-education-lesson-form {
    width: 100%;
    border-radius: 0 0 6px 6px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.1);
}

.c-education-lesson-form__header {
    color: $color-info;
    font-family: $font-family-secondary;
    font-size: 22px;
    font-weight: $font-weight-medium;
    letter-spacing: 0;
    line-height: 28px;
    padding: 27px 30px 21px;
    border-bottom: 1px solid #F1F1F1
}

.c-education-lesson-form__footer {
    @include flex-center-y;
    padding: 11px 30px;
    border-top: 1px solid #F1F1F1
}

.c-education-lesson-form__body {
    padding: 19px 30px 73px;
}
.c-education-lesson-form__btn {
    min-width: 94px;
}

.c-education-lesson-form__wysiwyg {
    .ql-container.ql-snow {
        height: 300px;
    }
}