.c-calendar-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px 0;
    min-height: 35px;
}

.c-calendar-header__main {
    @include flex-center;
    flex-shrink: 0;
    flex-grow: 0;
}

.c-calendar-header__dropdown {
    padding: 0 2px;
}

.c-calendar-header__dropdown--month {
    width: 125px;
}

.c-calendar-header__dropdown--year {
    width: 84px;
}

.c-calendar-header-week {
    width: 136px;
    background: #fff;
    border: 1px solid #F1F1F1;	
    border-radius: 13px;
    padding: 5px 10px;
    color: #727272;
    font-family: Muli, sans-serif;
    font-size: 13px;	
    line-height: 1.2em;
    text-align: center;

    span {
        text-transform: capitalize;
    }
}

.c-calendar-header__left,
.c-calendar-header__right {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
}

.c-calendar-header__view-btn {
    width: 66px;
    margin: 2px 0;
}