.c-patient-measurement-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.1);
    padding: 14px 10px 16px 10px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -28px;
        left: 0;
        width: 100%;
        height: 24px;
        clip-path: polygon(50% 24px, 0 0, 100% 0);
        opacity: 0;
        background-color: #60D1FE;
        transition: opacity 200ms ease-in-out;
    }
}

.c-patient-measurement-card__header {
    color: #727272;
    font-size: 12px;
    font-weight: $font-weight-medium;
    line-height: 1.5em;
    margin: 0 0 33px 0;
    text-align: center;
}


.c-patient-measurement-card__icon {
    height: 34px;
    text-align: center;
    margin: 0 0 12px 0;
}

.c-patient-measurement-card__icon-img {
    max-width: 46px;
    height: 100%;
}

.c-patient-measurement-card__value {
    color: #4A4A4A;
    font-size: 30px;
    font-weight: $font-weight-medium;
    letter-spacing: -0.7px;
    line-height: 1.4em;
    text-align: center;

    &.no-data {
        min-height: 70px;
        font-size: 22px;
        line-height: 2em;
    }
}

.c-patient-measurement-card__value-desc {
    color: rgba(#7D7D7D, 0.6);
    font-size: 10px;
    font-weight: $font-weight-semibold;
    line-height: 1.5em;
    text-align: center;
}

.c-patient-measurement-card__footer {
    color: rgba(#7D7D7D, 0.6);
    font-size: 10px;
    font-weight: $font-weight-semibold;
    letter-spacing: -0.5px;
    line-height: 1.3em;
    text-align: center;
    margin: 17px 0 0 0;
}

.c-patient-measurement-card.is-selected {
    &::after {
        opacity: 0.3;
    }
}

.c-patient-measurement-card.is-disabled {
    cursor: default;
}