button {
    border: none;
    margin: 0;
    padding: 5px 10px;
    width: auto;
    overflow: visible;
    background: #777;
    color: #fff;
    font: inherit;
    line-height: normal;
    cursor: pointer;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}
