.c-patient-graph-header__date {
    color: #7D7D7D;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 1.6em;
    text-align: center;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
}

.c-patient-graph-header__value {
    color: #4A4A4A;
    font-size: 30px;
    font-weight: $font-weight-medium;
    letter-spacing: -0.7px;
    line-height: 1.5em;
    text-align: center;
    min-height: 45px;
}

.c-patient-graph-header__value-description {
    color: rgba(#7D7D7D, 0.6);
    font-size: 10px;
    font-weight: $font-weight-semibold;
    letter-spacing: 0;
    line-height: 1.6em;
    text-align: center;
    min-height: 16px;
}
