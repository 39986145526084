.c-education-subchapter {
    display: flex;
    min-height: 100vh;
}

.c-education-subchapter__subchapter-card {
    flex-shrink: 0;
    width: 355px;
    min-height: 100%;
    box-shadow: 2px 0 14px -5px rgba(0,0,0,0.12);
}

.c-education-subchapter__main {
    width: 100%;
    flex-shrink: 1;
    padding: 46px 0 20px 0;
}