.c-report-bonuses {
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.1);
    margin: 20px 0 0;
    @media(min-width: 992px) {
        max-width: 835px;
    }
}

.c-report-bonuses__cell-1 {
    flex: 0 0 100%;
    max-width: 100%;
    @media(min-width: 992px) {
        flex: 0 0 52%;
        max-width: 52%;
    }
}

.c-report-bonuses__cell-2 {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 396px;
    @media(min-width: 992px) {
        flex: 0 0 48%;
        max-width: 48%;
        min-height: auto;
    }
}