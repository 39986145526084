.o-dropdown-item {
    display: flex;
    padding: 8px 12px;
    width: 100%;
    font-size: 13px;
    font-family: $font-family-primary;
    line-height: 1.2em;
    cursor: pointer;

    &, &:hover {
        color: #727272;
        text-decoration: none;
    }

    &:hover {
        background: darken(#fff, 5%);
    }

    &.is-disabled {
        color: rgba(#727272, 0.5);
    }
}

.o-dropdown-item__icon {
    display: block;
    max-width: 14px;
    max-height: 14px;
    width: auto;
    height: auto;
    margin: 0 8px 0 0;
}