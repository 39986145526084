.o-input-group {
    display: flex;
    width: 100%;
    height: 38px;
}

.o-input-group__input {
    height: 100%;
}

.o-input-group__append {
    height: 100%;
}

.o-input-group--append-btn {
    .o-input-group__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .o-input-group__append {
        margin: 0 0 0 -12px;
    }
}

