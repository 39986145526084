.c-chat-window {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.c-chat-window__chat {
    padding: 40px 18px 40px 40px;
    height: 100%;
    overflow: auto;
    flex-shrink: 1;
    overflow-y: scroll;
    @include scrollbar(transparent, $color-info, 12px);
}

.c-chat-window__form {
    flex-shrink: 0;
}

.c-chat-window__chat-refresh {
    position: absolute;
    top: 7px;
    left: 50%;
    z-index: 222;
    transform: translateX(-50%);

    .o-btn {
        border: 1px solid $color-info;
        color: $color-info;
        background: #fff;
        height: 28px;
        padding: 4px;
        font-weight: 400;
    }
}
