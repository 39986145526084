
.c-app-params-event-icon-preview__wrapper {
    display: flex;
    margin: 0 -8px;
}

.c-app-params-event-icon-preview__item {
    border-radius: 50%;
    width: 38px;
    height: 38px;
    @include flex-center;
    margin: 0 8px;
}

.c-app-params-event-icon-preview__item--incoming {
    background: $color-event-incoming;
}

.c-app-params-event-icon-preview__item--closest {
    background: $color-event-closest;
}

.c-app-params-event-icon-preview__item--past {
    background: $color-event-past;
}