.c-patient-calendar-legend {
    overflow: hidden;
    padding: 20px 0 0 0;
}

.c-patient-calendar-legend__item {
    @include flex-center;

    &::before {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin: 0 8px 0 0;
        flex-shrink: 0;
    }
}

.c-patient-calendar-legend__item-description {
    font-size: 12px;
    line-height: 1.5em;
    font-weight: $font-weight-medium;
    color: #cecece;
    flex-shrink: 1;
}

.c-patient-calendar-legend__item--past {
    &::before {
        background: $color-event-past;
    }
}

.c-patient-calendar-legend__item--closest {
    &::before {
        background: $color-event-closest;
    }
}

.c-patient-calendar-legend__item--incoming {
    &::before {
        background: $color-event-incoming;
    }
}
