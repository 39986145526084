.quill {
    border: 1px solid #D3D6DB;
    border-radius: 25px;
    background: #fff;

    &.disabled {
        background: #F8F8F8;
    }

    .ql-toolbar.ql-snow {
        border: 0;
        border-bottom: 1px solid #D3D6DB;
        padding: 13px 13px;
    }

    .ql-container.ql-snow {
        border: 0;
        height: 150px;
    }

    .ql-editor {
        padding: 16px 18px;
        font-family: $font-family-primary;
        color: #7D7D7D;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        word-break: break-word;

        @include editor-content();
        @include scrollbar(transparent, $color-info, 12px);
    }

    .ql-formats {
        padding: 0 15px 0 0;
    }

    .ql-formats:not(:last-child) {
        border-right: 1px solid #D3D6DB;
    }

    @include editor-content();
}