.c-hospital-events-head {
    display: flex;
    justify-content: space-between;
    margin: 0 0 21px;
}

.c-hospital-events-head__date-col {
    padding: 20px 0 0;
}

.c-hospital-events-head__sub-heading {
    margin: -6px 0 0;
    font-size: 13px;
    line-height: 16px;
    color: #727272;
}