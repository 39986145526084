.c-patient-measurements-list {
    display: flex;
    margin: -6px;
    flex-wrap: wrap;
}

.c-patient-measurements-item-activities {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px;

    @media (min-width: 1880px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

.c-patient-measurements-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 6px;

    @media (min-width: 1340px) {
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }

    @media (min-width: 1880px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.c-patient-measurements-graph-container {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 0 6px;

    &.two-in-row {
        @media (min-width: 1340px) {
            display: none;
        }
    }

    &.three-in-row {
        @media (max-width: 1339px) {
            display: none;
        }
        @media (min-width: 1879px) {
            display: none;
        }
    }

    &.five-in-row {
        @media (max-width: 1879px) {
            display: none;
        }
    }
}